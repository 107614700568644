import React from 'react'
const TxtIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66214 0H15.3548L22.7221 7.67917V22.6152C22.7221 24.4863 21.2084 26 19.3438 26H6.66214C4.79108 26 3.27734 24.4863 3.27734 22.6152V3.3848C3.27731 1.51374 4.79105 0 6.66214 0Z" fill="#251D36" />
            <path opacity="0.302" fill-rule="evenodd" clip-rule="evenodd" d="M15.3496 0V7.62069H22.7234L15.3496 0Z" fill="white" />
            <path d="M8.18921 18.9829V15.2863H6.83789V14.2598H10.7425V15.2863H9.39763V18.9829H8.18921ZM15.1343 18.9829H13.8674L13.0033 17.5341L12.1392 18.9829H10.8659L12.3666 16.4622L11.0543 14.2598H12.3212L13.0033 15.4032L13.679 14.2598H14.9524L13.64 16.4687L15.1343 18.9829ZM16.6025 18.9829V15.2863H15.2577V14.2598H19.1622V15.2863H17.8109V18.9829H16.6025Z" fill="white" />
        </svg>
    )
}
export default TxtIcon