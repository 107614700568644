import React, { useState } from 'react'
import { Modal } from '@mui/material';
import ChangePasswordForm from './ChangePasswordForm';
import CloseIcon from '@mui/icons-material/Close';
import ApiService from '../../services/ApiService';
import { showAlert } from '../../store/slices/alertSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';

type ChangePasswordProps = {
    open: boolean;
    setOpen: CallableFunction;
};

const ChangePassword: React.FC<ChangePasswordProps> = ({ open, setOpen }) => {
    const [email, setEmail] = useState<string>('chamikarox@gmail.com');
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const apiService = new ApiService();
    const dispatch = useDispatch<AppDispatch>();

    const handleSubmitNewPassword = async (e: React.FormEvent<HTMLFormElement>,) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            dispatch(showAlert({ message: 'New Passwords are different', severity: 'error' }))
            return;
        }
        setLoading(true);
        const data = {
            newPassword: newPassword,
            oldPassword: currentPassword,
        }
        try {
            await apiService.changeUserPassword(data).then((response) => {
                if (response.data.message) {
                    setConfirmPassword('');
                    setCurrentPassword('');
                    setNewPassword('');
                }
                setOpen(false);
            });
        } catch (error) {
            dispatch(showAlert({ message: 'Password Change failed', severity: 'error' }))
            console.error("Password reset failed", error);
            
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <div className='change-password-container'>
                <div className='close-button'>
                    <button onClick={() => { setOpen(false) }}>
                        <CloseIcon />
                    </button>
                </div>
                <div className='header-title'>
                    <span>
                        Change Password
                    </span>
                </div>
                <ChangePasswordForm
                    currentPassword={currentPassword}
                    newPassword={newPassword}
                    confirmPassword={confirmPassword}
                    loading={loading}
                    onChangeCurrentPassword={(e) => setCurrentPassword(e.target.value)}
                    onChangeNewPassword={(e) => setNewPassword(e.target.value)}
                    onChangeConfirmPassword={(e) => setConfirmPassword(e.target.value)}
                    onSubmit={handleSubmitNewPassword}
                />
            </div>
        </Modal>
    )
}

export default ChangePassword