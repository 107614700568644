import axios from "axios";
import { getBaseURL } from "../helpers/helperfunctions";
import AuthService from "./AuthService";
import { getFromLocalStorage, getTokens, saveTokens } from "../helpers/storageConfig";
import FirebaseFunctions from "../helpers/firebaseFunctions";

export default class ApiService {

    async getAllOrganizationUsers() {
        const ax = await this.ax();
        return await ax.get("/user/all-customers");
    }
    async getAllOrganizations() {
        const ax = await this.ax();
        return await ax.get("/organization/getAll");
    }

    async createLead(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/create", data);
    }

    async updateLead(data: any) {
        const ax = await this.ax();
        return await ax.put("/lead/update", data);
    }

    async createClient(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/createOrganizationUser", data);
    }

    async updateClient(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/update-customer", data);
    }

    async deleteLead(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/delete", data);
    }

    async getAllLeads() {
        const ax = await this.ax();
        return await ax.get("/lead/getAll");
    }

    async addLeadTask(data: any) {
        const ax = await this.ax();
        return await ax.post('lead/addTask', data)
    }

    async updateLeadUser(data: any) {
        const ax = await this.ax();
        return await ax.post('lead/update-lead-user', data)
    }

    async updateLeadTask(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/updateTask", data);
    }

    async deleteLeadTask(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/deleteTask", data);
    }

    async updateLeadStage(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/changeStage", data);
    }

    async getLeadById(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/get-by-id", data);
    }

    async getAllLogs(data: any) {
        const ax = await this.ax();
        return await ax.post("/log/getAll", data);
    }

    async createTemplate(data?: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/create-template", data);
    }

    async getItineraryPages(data?: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-all-pages", data);
    }

    async getGlobalPages() {
        const ax = await this.ax();
        return await ax.get("/itinerary/get-global-pages");
    }

    async getLeadViewItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-lead-itineraries", data);
    }

    async getAllItineraries() {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-all-itineraries");
    }

    async createLeadItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/create-lead-itinerary", data);
    }

    async getTemplates(data?: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-all-templates", data);
    }

    async getGlobalTemplates() {
        const ax = await this.ax();
        return await ax.get("/itinerary/get-global-templates");
    }

    async deleteItineraryDocument(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/delete-template", data);
    }

    async submitItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/submit-itinerary", data);
    }

    async getItineraryImage(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/map-image", data);
    }

    async generateItineraryPdf(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-itinerary-pdf", data, {
            responseType: 'blob'
        });
    }

    async createItineraryPage(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/create-itinerary-page", data, {
            responseType: 'blob'
        });
    }

    async getCalenderAllTasks() {
        const ax = await this.ax();
        return await ax.get("/calender/getAll");
    }

    async getAllUsers() {
        const ax = await this.ax();
        return await ax.get("/user/getAll");
    }

    async handleTaskAssignees(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/manage-task-assignee", data);
    }

    async handleLeadAssignees(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/manage-lead-assignee", data);
    }

    async updateLeadMedia(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/update-lead-media", data);
    }

    async getUserData() {
        const ax = await this.ax();
        return await ax.post("/user/getUser");
    }

    async editUserData(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/editUser", data);
    }

    async changeUserPassword(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/set-new-password", data);
    }

    private async ax() {
        let tenantId = getFromLocalStorage('tenantId');

        const apiClient = axios.create({
            baseURL: getBaseURL()
        });

        apiClient.interceptors.request.use(async (config) => {
            const token = getTokens();

            config.headers.Authorization = `Bearer ${token}`;
            config.headers['x-tenant-id'] = tenantId;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        apiClient.interceptors.response.use((response) => {
            return response;
        }, async (error) => {

            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const refreshToken = getFromLocalStorage('uid');

                const authService = new AuthService();
                const firebaseFunctions = new FirebaseFunctions();

                const response = await authService.refreshToken({ 'uid': refreshToken });
                const data = response.data;
                const token = data.tokens.token;
                const customToken = data.tokens.customToken;
                saveTokens(token);
                firebaseFunctions.authenticateUser(customToken);

                axios.defaults.headers.Authorization = `Bearer ${token}`;
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                originalRequest.headers['x-tenant-id'] = tenantId;
                return apiClient(originalRequest);
            }
            return Promise.reject(error);
        });
        return apiClient;
    }
}