import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ILoginData } from "../../helpers/Interfaces";
import userImg from "../../assets/images/user.svg"
import lockImg from "../../assets/images/lock.svg"
import eyeImg from "../../assets/images/eye.svg"
import eyeClose from "../../assets/images/eyeClose.svg"


const LoginForm = ({ handleLogin, loading, loginForm, setLoginForm }: { handleLogin: (e: React.FormEvent<HTMLFormElement>) => Promise<void>, loginForm: ILoginData, setLoginForm: CallableFunction, loading: boolean }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    return (<form onSubmit={handleLogin}
        className="login-form">
        <div className="form-group">
            <input required type="text" className="form-control" placeholder="Username or Email Address" onChange={(data) => {
                let newForm = loginForm;
                newForm.email = data.target.value;
                setLoginForm({ ...newForm });
            }} />
        </div>
        <div className="form-group">
            <input required type={showPassword ? 'text' : 'password'} className="form-control" placeholder="Password" onChange={(data) => {
                let newForm = loginForm;
                newForm.password = data.target.value;
                setLoginForm({ ...newForm });
            }} />
            <button onClick={togglePasswordVisibility} className="password-toggle" type="button"><img src={showPassword ? eyeClose : eyeImg} alt="" /></button>
        </div>
        <div className="sm-msg primary-font">
            <span className="inner"><img src={lockImg} alt="lock" /><span className="txt"><Link to="/forgot-password" style={{ color: 'inherit', textDecoration: 'none' }} > Forgot Password? </Link> </span></span>
        </div>
        <button type="submit" className="submit fz-16 primary-font fw-600 ttu c-white">{loading
            ? (<CircularProgress color="inherit" size={28} />)
            : ("Login")}</button>
        <div className="sm-msg primary-font">
            <span className="inner"><img src={userImg} alt="lock" /><span className="txt">Don’t have an account? <span className="c-orange">Talk to Support</span></span></span>
        </div>
    </form>);
}

export default LoginForm