import { useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { closeAlert } from '../../../store/slices/alertSlice';

export default function PositionedSnackbar() {
    const alert = useSelector((state: RootState) => state.alert);
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = useCallback(() => dispatch(closeAlert()), [])

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alert.open}
            onClose={handleClose}
            key="top-right"
            autoHideDuration={5000}
            sx={{
                width: '50%',
                maxWidth: '500px',
                minWidth: '200px',
            }}
        >
            <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }} variant="filled">
                {alert.message}
            </Alert>
        </Snackbar>
    );
}