import { CircularProgress } from "@mui/material"
import { MouseEventHandler } from "react"

const SaveIcon = (props: { loading?: boolean, onClick?: MouseEventHandler<SVGSVGElement> }) => {
    return (
        props.loading
            ? (<div style={{
                color: '#E27C37', width: '35px', height: '35px', borderRadius: '6px', border: '1px solid #E27C37',
                backgroundColor: '#F5E3D7', display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}><CircularProgress color="inherit" size={14} /></div>)
            : (<svg style={{ cursor: 'pointer' }} onClick={props.onClick} width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="49" height="49" rx="9.5" fill="#F5E3D7" stroke="#E27C37" />
                <g clip-path="url(#clip0_5186_13378)">
                    <path d="M31.4173 15H17.3438C16.0514 15 15 16.0514 15 17.3438V32.6562C15 33.9486 16.0514 35 17.3438 35H32.6562C33.9486 35 35 33.9486 35 32.6562V18.5827L31.4173 15ZM21.1328 16.5625H25.7812V19.5312H27.3438V16.5625H28.9062V20.3125C28.9062 20.7433 28.5558 21.0938 28.125 21.0938H21.9141C21.4833 21.0938 21.1328 20.7433 21.1328 20.3125V16.5625ZM30.4688 33.4375H19.5703V26.5625C19.5703 26.1317 19.9208 25.7812 20.3516 25.7812H29.6875C30.1183 25.7812 30.4688 26.1317 30.4688 26.5625V33.4375ZM33.4375 32.6562C33.4375 33.087 33.087 33.4375 32.6562 33.4375H32.0312V26.5625C32.0312 25.2702 30.9798 24.2188 29.6875 24.2188H20.3516C19.0592 24.2188 18.0078 25.2702 18.0078 26.5625V33.4375H17.3438C16.913 33.4375 16.5625 33.087 16.5625 32.6562V17.3438C16.5625 16.913 16.913 16.5625 17.3438 16.5625H19.5703V20.3125C19.5703 21.6048 20.6217 22.6562 21.9141 22.6562H28.125C29.4173 22.6562 30.4688 21.6048 30.4688 20.3125V16.5625H30.7702L33.4375 19.2298V32.6562Z" fill="#E27C37" />
                </g>
                <defs>
                    <clipPath id="clip0_5186_13378">
                        <rect width="20" height="20" fill="white" transform="translate(15 15)" />
                    </clipPath>
                </defs>
            </svg>)
    )
}

export default SaveIcon