import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import leadStagesReducer from "./slices/leadStagesSlice";
import sideBarReducer from "./slices/sideBarSlice";
import itineraryIdReducer from "./slices/itinerarySlice";
import alertReducer from "./slices/alertSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        leadStages: leadStagesReducer,
        sideBar: sideBarReducer,
        itinerary: itineraryIdReducer,
        alert: alertReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;