import { CSSProperties, ReactElement } from 'react'
import './SecondaryButton.scss'
import { CircularProgress } from '@mui/material'

type SecondaryButtonProps = {
    onClick?: () => void,
    label: string,
    icon?: ReactElement,
    loading?: boolean,
    style?: CSSProperties | undefined,
    hide?: boolean,
    borderButton?: boolean,
    redButton?: boolean
}

const SecondaryButton = ({ onClick, label, icon, loading, style, borderButton, redButton }: SecondaryButtonProps) => {
    return (
        <button style={style} disabled={loading ?? false} onClick={onClick} className={`button ${redButton ? `${borderButton ? 'red-border-btn' : 'red-content-btn'}` : borderButton ? 'border-btn': 'content-btn' }`} type="button">
            {icon
                ? (<span className="icon">
                    {icon}
                </span>)
                : (<></>)}
            <span className="txt fz-16 ttu primary-font">{loading ? (<CircularProgress size={16} color='inherit' />) : (label)}</span>
        </button>
    )
}

export default SecondaryButton