import { Modal } from "@mui/material";
import "./PdfPreview.scss";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";
import SendIcon from "../../../assets/images/builder/SendIcon";
import { downloadPdf } from "../../../helpers/helperfunctions";
import { useState } from "react";

type PdfPreviewProps = {
  open: boolean,
  close: CallableFunction,
  pdfLink: string,
  itineraryName: string,
  publish: () => void
}

const PdfPreview = (props: PdfPreviewProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="pdf-preview-container">
        <embed className='pdf-viewer' src={`${props.pdfLink}#toolbar=0`} type="application/pdf" />
        <div className="close-outer">
          <SecondaryButton label="Cancel" onClick={() => props.close()} />
          <div className="right-corner">
            <SecondaryButton loading={loading} label="Download" onClick={() => downloadPdf(props.pdfLink, props.itineraryName, setLoading)} />
            <SecondaryButton label="Confirm" onClick={() => {
              props.close();
              props.publish();
            }} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PdfPreview;
