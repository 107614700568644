
import { useEffect, useState } from "react";
import { ILoginData, ILoginResponse, IUser, IUserTenant } from "../../helpers/Interfaces";
import Functions from './loginFunctions'
import { CircularProgress } from "@mui/material";
import { setUser } from '../../store/slices/userSlice';
import { AppDispatch } from '../../store/store';
import { useDispatch } from "react-redux";
import { storeLocalStorage, saveTokens, saveUser } from "../../helpers/storageConfig";
import { useNavigate } from "react-router-dom";
import LoginForm from "./loginForm";
import { useTranslation } from "react-i18next";
import '../../i18n/config';
import { setLeadStages } from "../../store/slices/leadStagesSlice";
import FirebaseFunctions from "../../helpers/firebaseFunctions";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { leadTrackerStages } from "../../helpers/constants";

const Login = () => {
  const { t, i18n } = useTranslation();
  const functions = new Functions();
  const firebaseFunctions = new FirebaseFunctions();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState<ILoginData>({ email: '', password: '', organization: undefined });
  const [tenants, setTenants] = useState<IUserTenant[]>();
  const [selectedTenant, setSelectedTenant] = useState<IUserTenant>();
  const [loginUser, setLoginUser] = useState<ILoginResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(navigator.language)
  }, [])

  const handleLogin = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    const data = {
      email: loginForm.email,
      password: loginForm.password,
    }

    const user: ILoginResponse = await functions.userLogin(data, setLoading, dispatch);

    await firebaseFunctions.authenticateUser(user.firebaseCustomToken, dispatch);

    if (user.tenants.length === 1) return processLogin({ user: user, tenant: user.tenants[0] });

    setLoginUser(user)
    setTenants(user.tenants);
  }

  const handleSelectTenant = (value: any) => () => {
    setSelectedTenant(value);
  };

  const processLogin = async ({ user, tenant }: { user?: ILoginResponse, tenant?: IUserTenant }) => {

    if (!user || !tenant) return;

    const _user: IUser = {
      email: user.email,
      displayName: user.name,
      photoURL: user.photoUrl,
      tenantId: tenant.id,
      language: tenant.language
    }

    i18n.changeLanguage(tenant.language)

    saveTokens(user.token);
    storeLocalStorage('uid', user.uid);
    storeLocalStorage('firebaseCustomToken', user.firebaseCustomToken);
    storeLocalStorage('tenantId', tenant.id);
    saveUser(_user);
    dispatch(setUser(_user));
    dispatch(setLeadStages(tenant.stages ?? leadTrackerStages));
    storeLocalStorage('leadStages', (tenant.stages ? tenant.stages as string[] : leadTrackerStages).join(', '))
    navigate('/dashboard/lead-tracker');
  }

  const renderLoginContent = () => {
    if (tenants) {
      return (
        <form onSubmit={() => processLogin({ user: loginUser, tenant: selectedTenant })} className="auth-form">
          <h4 className="fz-18 primary-font fw-400 title"> Select your organization </h4>
          <div className="form-group">
            <input
              required
              type="text"
              className="form-control"
              placeholder="Organization"
              value={selectedTenant?.name}
              onChange={() => { }}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
            />
            {showDropdown && (
              <ul className="dropdown-menu show">
                {tenants.map((item, index) => (
                  <li key={index} className="dropdown-item" onClick={handleSelectTenant(item)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button disabled={loading} type="submit" className="submit fz-16 primary-font fw-600 ttu c-white">
            {loading ? <CircularProgress color="inherit" size={28} /> : "Continue"}
          </button>
        </form>
      );
    }

    return <LoginForm handleLogin={handleLogin} loginForm={loginForm} setLoginForm={setLoginForm} loading={loading} />
  }

  return (
    <AuthLayout contentData={{ title: t('loginHeader'), description: t('loginDescription') }} >
      {renderLoginContent()}
    </AuthLayout>
  )
}

export default Login

