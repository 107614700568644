import Dashboard from "../../../components/Dashboard/Dashboard"
import CalenderFunction from './CalenderFunctions'
import { useEffect, useState } from "react";
import { IGetAllCalenderLead, ILead, ISortedLeads } from "../../../helpers/Interfaces";
import CustomCalender from "../../../components/TostUi/CustomCalendar/CustomCalender";
import ViewLead from "../../../components/ViewLead/ViewLead";
import { DropdownItemProps } from "../../../components/Mui/CustomAutocomplete/CustomAutocomplete";
import LeadFunctions from "../LeadTracker/LeadFunctions";
import SearchableDropdown from "../../../components/SerchableDropdown/SearchableDropdown";
import { getUserId } from "../../../helpers/storageConfig";
import "./Calender.scss"

const Calender = () => {
    const functions = new CalenderFunction();
    const LeadTrackerFunctions = new LeadFunctions();
    const [loading, setLoading] = useState(false);
    const [viewNewLead, setOpenViewLead] = useState(false);
    const [openNewLead, setOpenNewLead] = useState(false);
    const [leadTasks, setLeadTasks] = useState<IGetAllCalenderLead[]>([]);
    const [selectedLead, setSelectedLead] = useState<ILead>({ customer: { name: '', phoneNumber: '', email: '', nationality: '' }, stage: 'NEW_LEAD' });
    const [users, setUsers] = useState<DropdownItemProps[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [filterUserId, setFilterUserId] = useState<string | undefined>();
    const [filterLead, setFilterLead] = useState({ user: false, other: false });

    useEffect(() => {
        functions.getAllTasks(setLeadTasks, setLoading);
        LeadTrackerFunctions.getAllUser(setUsers);
    }, [])

    const toggleViewLead = (newOpen: boolean, lead?: ILead) => {
        console.log(lead);

        if (lead) {
            setSelectedLead(lead);
        } else {
            setSelectedLead({ customer: { name: '', phoneNumber: '', email: '', nationality: '' }, stage: 'NEW_LEAD' });
        }
        setOpenViewLead(newOpen);
    };


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, lead?: ILead) => {
        if (lead) {
            setSelectedLead(lead)
        }
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDropDown = () => {
        setAnchorEl(null);
        setSelectedLead({ customer: { name: '', phoneNumber: '', email: '', nationality: '' }, stage: 'NEW_LEAD' });
    };

    const openDropdown = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const defaultAssignees: DropdownItemProps[] = selectedLead.assignees
        ? selectedLead.assignees.map(t => ({ id: t.id, label: t.name }))
        : [];

    const userFilter = (userId?: string, clear?: boolean) => {
        if (clear ?? false) {
            setFilterUserId(undefined);
            return setFilterLead({ user: false, other: false });
        }

        const id = userId ?? getUserId();
        setFilterUserId(id);
        setFilterLead({ user: !userId, other: !!userId });
    };


    const getTask = (): IGetAllCalenderLead[] => {
        if (!filterUserId) return leadTasks;

        return leadTasks.map(leadTask =>
            ({ ...leadTask, tasks: leadTask.tasks.filter(task => task.assignees.includes(filterUserId)) }))
    };

    const dropdownUsers = selectedLead.id ? users : users.filter(u => u.id !== getUserId());

    return (
        <Dashboard headerTitle="Calendar">
            <div>
                <CustomCalender filterLead={filterLead} handleClick={handleClick} userFilter={userFilter} leadTasks={getTask()}
                    onSelectCard={(lead) => toggleViewLead(true, lead)} />
                <ViewLead users={users} open={viewNewLead} close={() => toggleViewLead(false)} lead={selectedLead}
                    refresh={() => functions.getAllTasks(setLeadTasks, setLoading)} />
                <SearchableDropdown selectedItems={defaultAssignees} onSelect={(val) => {
                    handleCloseDropDown();
                    userFilter(val.id.toString())
                }} initialDetails={dropdownUsers} id={id} open={openDropdown} anchorEl={anchorEl} handleClose={handleCloseDropDown} />
            </div>
        </Dashboard >
    )
}

export default Calender