import { Modal } from "@mui/material";
import "./EditOrgUser.scss";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ICreateLeadUser, ICreateLeadUserError } from "../../helpers/Interfaces";
import { t } from "i18next";
import { getUsersFromList, validateEmail, validatePhoneNumber } from "../../helpers/helperfunctions";
import { CustomMuiPhone } from "../Mui/CustomPhoneNumber/CustomPhoneNumber";
import CustomTextField from "../Mui/CustomTextField/CustomTextField";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import ApiService from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { showAlert } from "../../store/slices/alertSlice";

type EditOrgUserProps = {
  open: boolean;
  close: CallableFunction;
  refresh: CallableFunction;
  user?: ICreateLeadUser;
  customers?: ICreateLeadUser[]
};

const EditOrgUser = (props: EditOrgUserProps) => {
  const [formData, setFormData] = useState<ICreateLeadUser>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [formDataError, setFormDataError] = useState<ICreateLeadUserError>({ name: false, phoneNumber: false, nationality: false, email: { error: false, message: '' } });
  const apiService = new ApiService();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!props.user) return;
    setFormData(props.user)
  }, [formData, props.user])

  const validateData = () => {
    let newError = formDataError;
    if (formData.name === '') {
      newError.name = true;
      setFormDataError({ ...newError });
      return false;
    } else {
      newError.name = false;
      setFormDataError({ ...newError });
    }

    if (!validatePhoneNumber(formData.phoneNumber)) {
      newError.phoneNumber = true;
      setFormDataError({ ...newError });
      return false;
    } else {
      newError.phoneNumber = false;
      setFormDataError({ ...newError });
    }

    if (formData.email === '' || !validateEmail(formData.email)) {
      newError.email.error = true;
      setFormDataError({ ...newError });
      return false;
    } else {
      newError.email.error = false;
      setFormDataError({ ...newError });
    }

    props.customers?.forEach(user => {

    })
    const isEmailDuplicate = props.customers?.some(
      (user) => user.email === formData.email && user.id !== formData.id
    );

    if (isEmailDuplicate) {
      newError.email.error = true;
      newError.email.message = "Email already exists";
      setFormDataError({ ...newError });
      return false

    } else {
      newError.email.error = false;
      setFormDataError({ ...newError });
    }

    if (formData.nationality === '') {
      newError.nationality = true;
      setFormDataError({ ...newError });
      return false;
    } else {
      newError.nationality = false;
      setFormDataError({ ...newError });
    }

    return true;
  }

  const updateUser = () => {
    if (!validateData()) return;

    setLoading(true);

    apiService.updateLeadUser(formData).then((response) => {
      props.close();
      props.refresh();
    }).catch((error) => {
      console.log(error);
      dispatch(showAlert({ message: 'Update Lead User Failed!', severity: 'error' }))
    }).finally(() => {
      setLoading(false)
    });
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="edit-org-container">
        <div className="close-outer">
          <span className="header-title">Update user</span>
          <button onClick={() => props.close()}>
            <Close />
          </button>
        </div>
        <div className="form-outer mb-40">
          <div className="form-group">
            <CustomTextField value={formData.name} size="small" required error={formDataError.name} helperText={t('fieldRequired')} label={t('name')} defaultValue={formData.name}
              onChange={(val) => {
                const data = formData;
                data.name = val.target.value;
                setFormData({ ...data })
              }} />
          </div>
          <div className="form-group">
            <CustomMuiPhone
              value={formData.phoneNumber ?? ''} error={formDataError.phoneNumber} required defaultValue={formDataError.phoneNumber} label={t('phoneNumber')} size="small" helperText="Invalid input, enter a valid value."
              onChange={(phoneNumber) => {
                const data = formData;
                data.phoneNumber = phoneNumber;
                setFormData({ ...data })
              }}
            />

          </div>
          <div className="form-group">
            <CustomTextField value={formData.email} error={formDataError.email.error} required defaultValue={formData.email} label={t('emailAddress')} size="small" helperText={formDataError.email.message ?? "Invalid input, enter a valid value."}
              type='email' onChange={(val) => {
                const data = formData;
                data.email = val.target.value;
                setFormData({ ...data })
              }} />
          </div>
          <div className="form-group">
            <CustomTextField value={formData.nationality} error={formDataError.nationality} required defaultValue={formData.nationality} label={t('nationality')} size="small" helperText="Invalid input, enter a valid value."
              onChange={(val) => {
                const data = formData;
                data.nationality = val.target.value;
                setFormData({ ...data })
              }} />
          </div>
        </div>
        <div className="footer-outer">
          <SecondaryButton loading={loading} label={"Update"} onClick={updateUser} />
        </div>
      </div>
    </Modal>
  );
};

export default EditOrgUser;
