import { Accordion, AccordionDetails, AccordionSummary, Dialog, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import "./EditProfile.scss"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTextField from '../Mui/CustomTextField/CustomTextField'
import { CustomMuiPhone } from '../Mui/CustomPhoneNumber/CustomPhoneNumber';
import CustomDateInput from '../Mui/CustomDateInput/CustomDateInput';
import CustomStageDropDown from '../Mui/CustomStageDropDown/CustomStageDropDown';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import TransparentButton from '../TransparentButton/TransparentButton';
import UsersIcon from '../../assets/images/builder/UsersIcon';
import UserIcon from '../../assets/images/builder/UserIcon';
import PluseSvg from '../../assets/images/buttons/PluseSvg';
import PageTypeIcon from "../../assets/images/builder/file-filled.svg";
import { CustomSelect } from '../Mui/CustomSelect/CustomSelect';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import { DropdownItemProps } from '../Mui/CustomAutocomplete/CustomAutocomplete';
import IconButton from '../IconButton/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ProfilePictureSelector from '../ProfilePictureSelector/ProfilePictureSelector';
import { t } from 'i18next';
import { IconCustomSelect } from '../Mui/IconCustomSelect/IconCustomSelect';
import { IUser } from '../../helpers/Interfaces';
import ApiService from '../../services/ApiService';
import FirebaseFunctions from '../../helpers/firebaseFunctions';
import FileUpload from '../FileUpload/FileUpload';


type EditProfile = {
    open: boolean,
    setOpen: CallableFunction;
    userData?: IUser;
}

type AccordionState = {
    primary: boolean;
    secondary: boolean;
    social: boolean;
};


const EditProfile = (props: EditProfile) => {
    const [expanded, setExpanded] = useState<AccordionState>({
        primary: true,
        secondary: true,
        social: true,
    });

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [editUser, setEditUser] = useState({} as IUser);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string>('');
    const [blobFile, setBlobFile] = useState<Blob | null>(null);


    const apiService = new ApiService();
    const firebaseService = new FirebaseFunctions();

    useEffect(() => {
        if (props.userData) {
            setLoading(true);
            setEditUser(props.userData)
            setSelectedFile(editUser.photoURL!);
            setLoading(false);
        }
    }, [props.userData])

    const socialMedia = [{
        label: 'LinkedIn',
        id: 'linkedin',
        icon: <LinkedInIcon style={{ color: '#0077b5' }} />
    },
    {
        label: 'Facebook',
        id: 'facebook',
        icon: <FacebookIcon style={{ color: '#4267B2' }} />
    },
    {
        label: 'Instagram',
        id: 'instagram',
        icon: <InstagramIcon style={{ color: '#C13584' }} />
    },
    {
        label: 'Twitter',
        id: 'twitter',
        icon: <XIcon style={{ color: '#000' }} />
    }
    ];


    const handleAccordionChange = (panel: keyof AccordionState) => () => {
        setExpanded((prev) => ({
            ...prev,
            [panel]: !prev[panel],
        }));
    };

    const handleFileChange = (fileList: FileList | null) => {
        const file = fileList?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                const base64String = reader.result as string;
                setSelectedFile(base64String);
                setBlobFile(null);
            }
            reader.onerror = () => {
                console.error("Error reading file as base64.");
            };
        }
    };

    const addSocialLink = () => {
        setEditUser((prevUser) => ({
            ...prevUser,
            socialLinks: [
                ...(prevUser.socialLinks ?? []),
                { link: '', platform: 'facebook' },
            ],
        }));
    };

    const handlePictureSelect = async (picture: string) => {
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
        <text x="50%" y="50%" font-size="30" text-anchor="middle" alignment-baseline="central">${picture}</text>
    </svg>`;

        const svgBlob = new Blob([svg], { type: 'image/svg+xml' });

        setBlobFile(svgBlob);

        const reader = new FileReader();
        reader.readAsDataURL(svgBlob);


        reader.onloadend = async () => {
            let base64String = reader.result as string;
            setSelectedFile(base64String);
        };

        reader.onerror = () => {
            console.error("Error converting SVG Blob to Base64.");
        };
    };

    const updateUserData = async () => {
        try {
            setLoading(true)
            let profileUrl = editUser.photoURL;
            if (blobFile != null) {
                profileUrl = await firebaseService.uploadBlob(blobFile, editUser.email!)
            }
            else {
                if (selectedFile) {
                    profileUrl = await firebaseService.uploadBase64Image(selectedFile, editUser.email!)
                }
            }
            const data = { ...editUser, photoURL: profileUrl };

            setEditUser(data);

            await apiService.editUserData(data).then((response) => {
                console.log(response.status);
            });

            setLoading(false);
            props.setOpen(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <div className="popup-container">
                <span className="header-title">
                    {t('edit') + ' ' + t('personalInformation')}
                </span>
                <div className='sub-container'>
                    <div className='info-section'>
                        <Accordion
                            expanded={expanded.primary}
                            onChange={handleAccordionChange('primary')}>
                            <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} aria-controls="panel4-content" id="panel4-header">
                                <Typography>
                                    <img src={PageTypeIcon} alt="" /> {t('primaryInformation')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian-details detailed-itinerary">
                                <div className='accordion-section'>
                                    <CustomMuiPhone value={editUser.phoneNumber ?? ''} onChange={(newValue) => {
                                        setEditUser((prevUser) => ({
                                            ...prevUser,
                                            phoneNumber: newValue
                                        }));
                                    }} size='small' label={t('contactNumber')} defaultValue={editUser.phoneNumber} />
                                    <CustomTextField label={t('emailAddress')} size='small' defaultValue={editUser.email} value={editUser.email} onChange={
                                        (newValue) => {
                                            setEditUser((prevUser) => ({
                                                ...prevUser,
                                                email: newValue.target.value,
                                            }));
                                        }
                                    } disabled />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <hr />
                        <Accordion
                            expanded={expanded.secondary}
                            onChange={handleAccordionChange('secondary')}>
                            <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} aria-controls="panel4-content" id="panel4-header">
                                <Typography>
                                    <img src={PageTypeIcon} alt="" /> {t('secondaryInformation')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='accordion-section'>
                                    <CustomDateInput maxYearsBack={16} disableFuture defaultValue={editUser.dob?.toString()} label={t('dateofBirth')} onChange={(newValue) => {
                                        if (!newValue) return;
                                        setEditUser((prevUser) => ({
                                            ...prevUser,
                                            dob: newValue
                                        }));
                                    }} />
                                    <CustomTextField defaultValue={editUser.gender} label={t('Gender')} size='small' onChange={(newValue) => {
                                        let genderLower = (newValue.target.value).toLowerCase();
                                        setEditUser((prevUser) => ({
                                            ...prevUser,
                                            gender: genderLower,
                                        }));
                                    }} />
                                    <CustomTextField label={t('nationality')} size='small' onChange={(newValue) => {
                                        setEditUser((prevUser) => ({
                                            ...prevUser,
                                            nationality: newValue.target.value,
                                        }));
                                    }} defaultValue={editUser.nationality} value={editUser.nationality} />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <hr />
                        <Accordion
                            expanded={expanded.social}
                            onChange={handleAccordionChange('social')}>
                            <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} aria-controls="panel4-content" id="panel4-header">
                                <Typography>
                                    <img src={PageTypeIcon} alt="" /> {t('socialAccounts')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='accordion-social'>
                                    {editUser.socialLinks ? editUser.socialLinks.map((social, index) => (
                                        <div className="social-row" key={index}>
                                            <div className="social-dropdown">
                                                <IconCustomSelect
                                                    options={socialMedia}
                                                    onSelect={(selected) => {
                                                        setEditUser((prevUser) => {
                                                            const updatedSocialLinks = [...prevUser.socialLinks!];
                                                            updatedSocialLinks[index].platform = selected.id;
                                                            return { ...prevUser, socialLinks: updatedSocialLinks };
                                                        });
                                                    }}
                                                    defaultValue={socialMedia.find((media) => media.id === social.platform)}
                                                    require={false}
                                                    size="medium"
                                                />
                                            </div>
                                            <CustomTextField
                                                label="Link Account"
                                                size="small"
                                                value={social.link}
                                                onChange={(event) => {
                                                    const newLink = event.target.value;
                                                    setEditUser((prevUser) => {
                                                        const updatedSocialLinks = [...prevUser.socialLinks!];
                                                        updatedSocialLinks[index].link = newLink;
                                                        return { ...prevUser, socialLinks: updatedSocialLinks };
                                                    });
                                                }}
                                            />
                                        </div>
                                    )) : <></>}
                                    <div>
                                        <IconButton icon={<AddIcon style={{ color: '#E27C37' }} />} label='Add Another Social' onClick={addSocialLink} />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='profile-picture-section'>
                        <img src={(selectedFile && selectedFile !== '') ? selectedFile : "https://www.shutterstock.com/image-vector/avatar-gender-neutral-silhouette-vector-600nw-2470054311.jpg"} alt="Profile" />
                        <FileUpload onSelect={(val) => { handleFileChange(val) }} icon={<SecondaryButton label='Upload Photo' />} />
                        <ProfilePictureSelector avatars={[]} selectedPicture={''} handlePictureClick={handlePictureSelect} />
                    </div>
                </div>
                <div className='button-section'>
                    <SecondaryButton label={t('cancel')} onClick={() => props.setOpen(false)} />
                    <SecondaryButton label={t('submit')} onClick={() => { updateUserData(); console.log(selectedFile) }} loading={loading} />
                </div>
            </div>

        </Modal>
    )
}

export default EditProfile