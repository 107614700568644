import React from 'react'

const EditButton = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1388_513)">
                <path d="M19.1458 0.674039L19.1217 0.652083C18.6614 0.231597 18.0647 0 17.4415 0C16.7428 0 16.0721 0.295745 15.6011 0.811334L6.69544 10.5612C6.61427 10.6501 6.5527 10.7549 6.51463 10.8691L5.46746 14.0083C5.34639 14.3713 5.40715 14.7727 5.62998 15.082C5.85458 15.3937 6.21642 15.5799 6.59799 15.5799C6.76303 15.5799 6.9244 15.5459 7.0774 15.479L10.1094 14.1526C10.2196 14.1044 10.3185 14.0336 10.3996 13.9447L19.3053 4.19489C20.2321 3.18039 20.1606 1.60106 19.1458 0.674039ZM7.47381 13.4284L8.08829 11.5863L8.14011 11.5296L9.30475 12.5933L9.25292 12.6501L7.47381 13.4284ZM18.0356 3.03501L10.4646 11.3236L9.29999 10.2599L16.8709 1.97121C17.019 1.80909 17.2216 1.71978 17.4415 1.71978C17.6344 1.71978 17.8192 1.79155 17.9622 1.92214L17.9862 1.9441C18.3006 2.23124 18.3227 2.72063 18.0356 3.03501Z" fill="#E27C37" />
                <path d="M17.4181 7.93316C16.9432 7.93316 16.5582 8.31816 16.5582 8.79305V16.0933C16.5582 17.2991 15.5772 18.2802 14.3714 18.2802H3.94956C2.74371 18.2802 1.76275 17.2991 1.76275 16.0933V5.7562C1.76275 4.55041 2.74377 3.56939 3.94956 3.56939H11.4929C11.9678 3.56939 12.3528 3.18439 12.3528 2.7095C12.3528 2.23461 11.9678 1.84961 11.4929 1.84961H3.94956C1.79542 1.84961 0.0429688 3.60212 0.0429688 5.7562V16.0933C0.0429688 18.2474 1.79548 19.9999 3.94956 19.9999H14.3713C16.5254 19.9999 18.2779 18.2474 18.2779 16.0933V8.79305C18.278 8.31816 17.893 7.93316 17.4181 7.93316Z" fill="#E27C37" />
            </g>
            <defs>
                <clipPath id="clip0_1388_513">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EditButton