import { t } from "i18next";
import { DropdownItemProps } from "../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { ILeadStageLocalization } from "./Interfaces";

export const nationality: DropdownItemProps[] = [
    { label: "Sri Lankan", id: 1 },
    { label: "Indian", id: 2 },
    { label: "American", id: 3 },
    { label: "Americansdadas dsas", id: 4 },
];

export const modeOfArrival: DropdownItemProps[] = [
    { label: 'notDecidedYet', id: 1 },
    { label: 'airplane', id: 2 },
    { label: 'train', id: 3 },
    { label: 'cruiseShip', id: 4 },
    { label: 'privateTransfer', id: 5 },
    { label: 'busCoach', id: 6 },
    { label: 'ferry', id: 7 },
    { label: 'other', id: 8 }
];

export const modeOfDeparture: DropdownItemProps[] = [
    { label: 'sameAsArrival', id: 1 },
    { label: 'notDecidedYet', id: 2 },
    { label: 'airplane', id: 3 },
    { label: 'train', id: 4 },
    { label: 'cruiseShip', id: 5 },
    { label: 'privateTransfer', id: 6 },
    { label: 'busCoach', id: 7 },
    { label: 'ferry', id: 8 },
    { label: 'other', id: 9 }
];

export const visaRequirement: DropdownItemProps[] = [
    { label: 'clientRequiresAssistance', id: 1 },
    { label: 'visaOnArrival', id: 2 },
    { label: 'clientObtainedVisa', id: 3 },
    { label: 'noVisaRequired', id: 4 },
    { label: 'unsure', id: 5 }
];

export const arrivalDeparture: DropdownItemProps[] = [
    { label: 'requiresAssistance', id: 1 },
    { label: 'independentArrival', id: 2 },
    { label: 'unsure', id: 3 }
];

export const spacialCargoEquipment: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'liveAnimals', id: 2 },
    { label: 'excessBaggage', id: 3 },
    { label: 'sportsLeisureEquipment', id: 4 },
    { label: 'musicalInstruments', id: 5 },
    { label: 'firearmsAmmunition', id: 6 },
    { label: 'perishables', id: 7 },
    { label: 'divingEquipment', id: 8 },
    { label: 'campingGear', id: 9 },
    { label: 'filmEquipment', id: 10 },
    { label: 'medicalEquipment', id: 11 },
    { label: 'mobilityAids', id: 12 },
    { label: 'oversizedFragileItems', id: 13 },
    { label: 'dangerousGoods', id: 14 },
    { label: 'excessLuggage', id: 15 },
    { label: 'diplomaticCargo', id: 16 },
    { label: 'petsServiceAnimals', id: 17 },
    { label: 'audioVisualEquipment', id: 18 },
    { label: 'electronics', id: 19 },
    { label: 'machinery', id: 20 },
    { label: 'householdGoods', id: 21 },
    { label: 'humanRemains', id: 22 },
    { label: 'otherSpecifyInComments', id: 23 }
];
export const accessibilityRequirements: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'mobilityAssistance', id: 2 },
    { label: 'visualImpairment', id: 3 },
    { label: 'hearingImpairment', id: 4 },
    { label: 'lowerFloorsPreferred', id: 5 },
    { label: 'noStairsAccessNeeded', id: 6 },
    { label: 'requiresAccessibleBathroom', id: 7 },
    { label: 'requiresInRoomAssistance', id: 8 },
    { label: 'otherSpecifyInComments', id: 9 }
];

export const dietaryRestrictions: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'vegetarian', id: 2 },
    { label: 'vegan', id: 3 },
    { label: 'glutenFree', id: 4 },
    { label: 'dairyFree', id: 5 },
    { label: 'nutFree', id: 6 },
    { label: 'pescatarian', id: 7 },
    { label: 'kosher', id: 8 },
    { label: 'halal', id: 9 },
    { label: 'diabetic', id: 10 },
    { label: 'otherSpecifyInComments', id: 11 }
];

export const allergyRestrictions: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'peanutsTreeNuts', id: 2 },
    { label: 'shellfish', id: 3 },
    { label: 'gluten', id: 4 },
    { label: 'dairy', id: 5 },
    { label: 'eggs', id: 6 },
    { label: 'soy', id: 7 },
    { label: 'wheat', id: 8 },
    { label: 'sesame', id: 9 },
    { label: 'fish', id: 10 },
    { label: 'environmentalAllergens', id: 11 },
    { label: 'otherSpecifyInComments', id: 12 }
];

export const typeOfTrip: DropdownItemProps[] = [
    { label: 'beachVacation', id: 1 },
    { label: 'spaWellnessRetreat', id: 2 },
    { label: 'allInclusiveResortStay', id: 3 },
    { label: 'luxuryGetaway', id: 4 },
    { label: 'natureMindfulnessExperience', id: 5 },
    { label: 'wildlifeSafari', id: 6 },
    { label: 'hikingTrekkingAdventure', id: 7 },
    { label: 'backpackingCampingTrip', id: 8 },
    { label: 'whitewaterRaftingKayaking', id: 9 },
    { label: 'offBeatenPathExploration', id: 10 },
    { label: 'historicalCulturalTour', id: 11 },
    { label: 'foodWineTour', id: 12 },
    { label: 'volunteerEcoTourismTrip', id: 13 },
    { label: 'languageImmersionProgram', id: 14 },
    { label: 'festivalCulturalEventExperience', id: 15 },
    { label: 'culinaryTourCookingClass', id: 16 },
    { label: 'photographyWorkshopTour', id: 17 },
    { label: 'cyclingBikingAdventure', id: 18 },
    { label: 'divingSnorkelingTrip', id: 19 },
    { label: 'artArchitectureTour', id: 20 },
    { label: 'romanticGetaway', id: 21 },
    { label: 'familyVacation', id: 22 },
    { label: 'soloTravelAdventure', id: 23 },
    { label: 'soloFemaleTravelExperience', id: 24 },
    { label: 'lgbtqTravelTour', id: 25 },
    { label: 'digitalNomadRetreat', id: 26 },
    { label: 'workationPackageCoWorkingSpace', id: 27 },
    { label: 'luxuryHotelBusinessAmenities', id: 28 },
    { label: 'cityBreakRemoteWorkOptions', id: 29 },
    { label: 'wellnessRetreatRemoteWorkFlexibility', id: 30 },
    { label: 'otherSpecifyInComments', id: 32 }
];

export const travelStyle: DropdownItemProps[] = [
    { label: 'budget', id: 1 },
    { label: 'midRange', id: 2 },
    { label: 'luxury', id: 3 },
    { label: 'allInclusive', id: 4 },
    { label: 'backpacking', id: 5 }
];

export const pointOfReferrals: DropdownItemProps[] = [
    { label: 'whatsapp', id: 1 },
    { label: 'facebook', id: 2 },
    { label: 'phoneCall', id: 3 },
    { label: 'email', id: 4 },
    { label: 'website', id: 5 },
    { label: 'referringPartner', id: 6 },
    { label: 'tradeShow', id: 7 },
];

export const levelOfActivity: DropdownItemProps[] = [
    { label: 'relaxed', id: 1 },
    { label: 'moderate', id: 2 },
    { label: 'active', id: 3 },
    { label: 'adventurous', id: 4 }
];

export const preferredPace: DropdownItemProps[] = [
    { label: 'leisurely', id: 1 },
    { label: 'moderate', id: 2 },
    { label: 'fastPaced', id: 3 },
    { label: 'inDepth', id: 4 }
];

export const preferredLandscapes: DropdownItemProps[] = [
    { label: 'openToAll', id: 1 },
    { label: 'mountainsHikingTrails', id: 2 },
    { label: 'beachesCoastlines', id: 3 },
    { label: 'desertsAridLandscapes', id: 4 },
    { label: 'rainforestsJungles', id: 5 },
    { label: 'lakesRivers', id: 6 },
    { label: 'citiesUrbanCulture', id: 7 },
    { label: 'countrysideRuralCharm', id: 8 },
    { label: 'islandsArchipelagos', id: 9 },
    { label: 'glaciersArcticRegions', id: 10 },
    { label: 'volcanicLandscapesHotSprings', id: 11 },
    { label: 'historicalSitesCulturalLandmarks', id: 12 },
    { label: 'nationalParksWildlifeReserves', id: 13 }
];

export const climatePreferences: DropdownItemProps[] = [
    { label: 'openToAll', id: 1 },
    { label: 'warmSunny', id: 2 },
    { label: 'moderateSpringWeather', id: 3 },
    { label: 'coolRefreshing', id: 4 },
    { label: 'avoidingExtremeHeatHumidity', id: 5 },
    { label: 'avoidingExtremeColdSnow', id: 6 }
];

export const culturalInterests: DropdownItemProps[] = [
    { label: 'ancientHistoryRuins', id: 1 },
    { label: 'medievalHistoryCastles', id: 2 },
    { label: 'worldWarMilitaryHistory', id: 3 },
    { label: 'museumsArtGalleries', id: 4 },
    { label: 'religiousSitesPilgrimage', id: 5 },
    { label: 'classicalRenaissanceArt', id: 6 },
    { label: 'modernContemporaryArt', id: 7 },
    { label: 'streetArtMurals', id: 8 },
    { label: 'architecturalStylesLandmarks', id: 9 },
    { label: 'unescoWorldHeritageSites', id: 10 },
    { label: 'wineCulinaryExperiences', id: 11 },
    { label: 'streetFoodLocalMarkets', id: 12 },
    { label: 'coffeeCultureCafes', id: 13 },
    { label: 'traditionalFestivalsEvents', id: 14 },
    { label: 'cookingClassesFoodTours', id: 15 },
    { label: 'operaBalletClassicalMusic', id: 16 },
    { label: 'theaterLivePerformances', id: 17 },
    { label: 'traditionalMusicDance', id: 18 },
    { label: 'concertsMusicFestivals', id: 19 },
    { label: 'nightlifeEntertainment', id: 20 },
    { label: 'wildlifeWatchingSafaris', id: 21 },
    { label: 'hikingTrekkingAdventures', id: 22 },
    { label: 'nationalParksOutdoorRecreation', id: 23 },
    { label: 'scubaDivingSnorkeling', id: 24 },
    { label: 'ecotourismSustainableTravel', id: 25 },
    { label: 'shoppingLocalCrafts', id: 26 },
    { label: 'relaxationWellnessRetreats', id: 27 },
    { label: 'volunteerProgramsCulturalExchange', id: 28 },
    { label: 'learningNewLanguage', id: 29 },
    { label: 'uniqueCulturalExperiences', id: 30 },
    { label: 'other', id: 31 },
    { label: 'otherSpecifyInComments', id: 32 }
];

export const daysAndNights: DropdownItemProps[] = [
    { label: 'days1', id: 1 },
    { label: 'days2', id: 2 },
    { label: 'days3', id: 3 },
    { label: 'days4', id: 4 },
    { label: 'days5', id: 5 },
    { label: 'days6', id: 6 },
    { label: 'days7', id: 7 },
    { label: 'days8', id: 8 },
    { label: 'days9', id: 9 },
    { label: 'days10', id: 10 },
    { label: 'days11', id: 11 },
    { label: 'days12', id: 12 },
    { label: 'days13', id: 13 },
    { label: 'days14', id: 14 },
    { label: 'days15', id: 15 },
    { label: 'days16', id: 16 },
    { label: 'days17', id: 17 },
    { label: 'days18', id: 18 },
    { label: 'days19', id: 19 },
    { label: 'days20', id: 20 },
    { label: 'days21', id: 21 },
    { label: 'days22', id: 22 },
    { label: 'days23', id: 23 },
    { label: 'days24', id: 24 },
    { label: 'days25', id: 25 },
    { label: 'days26', id: 26 },
    { label: 'days27', id: 27 },
    { label: 'days28', id: 28 },
    { label: 'days29', id: 29 },
    { label: 'days30', id: 30 },
    { label: 'days31', id: 31 },
    { label: 'days32', id: 32 },
    { label: 'days33', id: 33 },
    { label: 'days34', id: 34 },
    { label: 'days35', id: 35 },
    { label: 'days36', id: 36 },
    { label: 'days37', id: 37 },
    { label: 'days38', id: 38 },
    { label: 'days39', id: 39 },
    { label: 'days40', id: 40 },
    { label: 'days41', id: 41 },
    { label: 'days42', id: 42 },
    { label: 'days43', id: 43 },
    { label: 'days44', id: 44 },
    { label: 'days45', id: 45 },
    { label: 'days46', id: 46 },
    { label: 'days47', id: 47 },
    { label: 'days48', id: 48 },
    { label: 'days49', id: 49 },
    { label: 'days50', id: 50 },
    { label: 'days51', id: 51 },
    { label: 'days52', id: 52 },
    { label: 'days53', id: 53 },
    { label: 'days54', id: 54 },
    { label: 'days55', id: 55 },
    { label: 'days56', id: 56 },
    { label: 'days57', id: 57 },
    { label: 'days58', id: 58 },
    { label: 'days59', id: 59 },
    { label: 'days60', id: 60 },
    { label: 'days61', id: 61 },
    { label: 'days62', id: 62 },
    { label: 'days63', id: 63 },
    { label: 'days64', id: 64 },
    { label: 'days65', id: 65 },
    { label: 'days66', id: 66 },
    { label: 'days67', id: 67 },
    { label: 'days68', id: 68 },
    { label: 'days69', id: 69 },
    { label: 'days70', id: 70 },
    { label: 'days71', id: 71 },
    { label: 'days72', id: 72 },
    { label: 'days73', id: 73 },
    { label: 'days74', id: 74 },
    { label: 'days75', id: 75 },
    { label: 'days76', id: 76 },
    { label: 'days77', id: 77 },
    { label: 'days78', id: 78 },
    { label: 'days79', id: 79 },
    { label: 'days80', id: 80 },
    { label: 'days81', id: 81 },
    { label: 'days82', id: 82 },
    { label: 'days83', id: 83 },
    { label: 'days84', id: 84 },
    { label: 'days85', id: 85 },
    { label: 'days86', id: 86 },
    { label: 'days87', id: 87 },
    { label: 'days88', id: 88 },
    { label: 'days89', id: 89 },
    { label: 'days90', id: 90 }
];

export const templateCategory: DropdownItemProps[] = [
    { label: 'Accommodation', id: 1 },
    { label: 'Activities', id: 2 },
    { label: 'Attraction', id: 3 },
    { label: 'Transport', id: 4 },
    { label: 'General', id: 5 },
];

export const leadStage: ILeadStageLocalization[] = [
    {
        key: "newlead",
        color: "#FFD600"
    },
    {
        key: "proposal",
        color: "#E27C37"
    },
    {
        key: "follow-up",
        color: "#E27C37"
    },
    {
        key: "accepted",
        color: "#75D2B8"
    },
    {
        key: "quotesent",
        color: "#75D2B8"
    },
    {
        key: "tripplaning",
        color: "#134B5F"
    },
    {
        key: "guestwel",
        color: "#134B5F"
    },
    {
        key: "ongoingtravel",
        color: "#75D2B8"
    },
    {
        key: "complete",
        color: "#2BA74E"
    },
    {
        key: "inhold",
        color: "#FFD600"
    },
    {
        key: "lost",
        color: "#F43131"
    }
];

export const emptyTemplate = {
    "width": 1080,
    "height": 1080,
    "fonts": [],
    "pages": [
        {
            "id": "NKnZWopCS8",
            "children": [],
            "width": "auto",
            "height": "auto",
            "background": "white",
            "bleed": 0,
            "duration": 5000
        }
    ],
    "audios": [],
    "unit": "px",
    "dpi": 72
};


export const leadTrackerStages = [
    'new lead', 'proposal', 'quote sent', 'follow-up', 'accepted', 'trip planning', 'guest welcoming',
    'on going travel', 'completed', 'on hold', 'lost'
]; export const taskStages = ['COMPLETED', "IN-PROGRESS", 'PENDING'] as any;
