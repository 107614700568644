import { BaseTextFieldProps, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { CountryIso2, defaultCountries, FlagImage, parseCountry, usePhoneInput } from "react-international-phone";
import './CustomPhoneNumber.scss'

export interface CustomMuiPhoneProps extends BaseTextFieldProps {
    value: string;
    onChange: (phone: string) => void;
}

export const CustomMuiPhone = ({ value, onChange, ...restProps }: CustomMuiPhoneProps) => {

    const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        defaultCountry: 'fr',
        value,
        countries: defaultCountries,
        onChange: (data) => {
            onChange(data.phone);
        }
    });

    return (
        <TextField
            classes={{ root: 'custom-mui-field custom-phone-field ' }} fullWidth={restProps.fullWidth !== false} required={restProps.required} select={restProps.select} defaultValue={restProps.defaultValue} variant='outlined' margin='normal'
            helperText={restProps.error ? restProps.helperText : null} size={restProps.size} placeholder={restProps.placeholder} label={restProps.label} type='tel' value={phone} disabled={restProps.disabled}
            error={restProps.error} onChange={handlePhoneValueChange} onFocus={restProps.onFocus} onBlur={restProps.onBlur} inputProps={restProps.inputProps} multiline={restProps.multiline} rows={restProps.rows} inputRef={inputRef}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: "2px", marginLeft: "-8px" }}
                    >
                        <Select
                        disabled={restProps.disabled}
                            MenuProps={{
                                style: {
                                    height: "360px",
                                    width: "360px",
                                    top: "10px",
                                    left: "-34px"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                }
                            }}
                            sx={{
                                width: "max-content",
                                fieldset: {
                                    color: 'transparent',
                                    display: "none"
                                },
                            }}
                            value={country}
                            onChange={(e) => setCountry(e.target.value as CountryIso2)}
                            renderValue={(value) => (
                                <FlagImage iso2={value.iso2} style={{ display: "flex" }} />
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagImage
                                            iso2={country.iso2}
                                            style={{ marginRight: "8px" }}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                )
            }}
        />
    );
};
