import React from 'react'

const BorderAdd = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1388_618)">
                <path d="M15 1.66667C16.8383 1.66667 18.3333 3.16167 18.3333 5V15C18.3333 16.8383 16.8383 18.3333 15 18.3333H5C3.16167 18.3333 1.66667 16.8383 1.66667 15V5C1.66667 3.16167 3.16167 1.66667 5 1.66667H15ZM15 0H5C2.23833 0 0 2.23833 0 5V15C0 17.7617 2.23833 20 5 20H15C17.7617 20 20 17.7617 20 15V5C20 2.23833 17.7617 0 15 0Z" fill="#E27C37" />
                <path d="M10.0013 15C9.5413 15 9.16797 14.6275 9.16797 14.1667V5.83333C9.16797 5.37333 9.5413 5 10.0013 5C10.4613 5 10.8346 5.37333 10.8346 5.83333V14.1667C10.8346 14.6275 10.4613 15 10.0013 15Z" fill="#E27C37" />
                <path d="M5 9.99935C5 9.53935 5.3725 9.16602 5.83333 9.16602H14.1667C14.6267 9.16602 15 9.53935 15 9.99935C15 10.4593 14.6267 10.8327 14.1667 10.8327H5.83333C5.3725 10.8327 5 10.4593 5 9.99935Z" fill="#E27C37" />
            </g>
            <defs>
                <clipPath id="clip0_1388_618">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BorderAdd