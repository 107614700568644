import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AlertMessage = { open?: boolean, message: string, severity: 'error' | 'success' | 'info' | 'warning' };

const initialState: AlertMessage = { open: false, message: '', severity: 'success' };

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        showAlert: (state, action: PayloadAction<AlertMessage>) => {
            return { ...action.payload, open: true };
        },
        closeAlert: () => {
            return { open: false, message: '', severity: 'error' } as AlertMessage;
        }
    }
});

export const { showAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;