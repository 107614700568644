import CustomDateInput from '../Mui/CustomDateInput/CustomDateInput';
import CustomAutocomplete from '../Mui/CustomAutocomplete/CustomAutocomplete';
import { CustomSelect } from '../Mui/CustomSelect/CustomSelect';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from 'react';
import Stepper from '../Stepper/Stepper';
import { ICreateLeadData, ILeadValidation, IStepperItem } from '../../helpers/Interfaces';
import { getDefaultValueInDropdown } from '../../helpers/helperfunctions';
import { arrivalDeparture, daysAndNights, modeOfArrival, modeOfDeparture, visaRequirement } from '../../helpers/constants';
import CustomTextField from '../Mui/CustomTextField/CustomTextField';
import TransparentButton from '../TransparentButton/TransparentButton';
import ExactDateSvg from '../../assets/images/buttons/ExactDateSvg';
import TentativeSvg from '../../assets/images/buttons/TentativeSvg';
import { t } from 'i18next';
import GoogleSearchBox from '../GoogleSearchBox/GoogleSearchBox';
import dayjs from 'dayjs';

type ArrivalDepartureProps = {
  divARef: React.MutableRefObject<HTMLDivElement | null>
  divBRef: React.MutableRefObject<HTMLDivElement | null>
  stepperSteps: IStepperItem[],
  lead: ICreateLeadData,
  setLead: CallableFunction
  leadValidation: ILeadValidation
}

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      content: '"Yes"',
      left: 12.8,
      fontSize: "10px",
      color: "#fff",
      lineHeight: "1.7",
    },
    "&::after": {
      content: '"No"',
      right: 12.8,
      fontSize: "10px",
      color: "#fff",
      lineHeight: "1.7",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const ArrivalDeparture = ({ divBRef, divARef, stepperSteps, lead, setLead, leadValidation }: ArrivalDepartureProps) => {
  const [exactDate, setExactDate] = useState<boolean>(true);

  useEffect(() => {
    if (!lead.startDate) {
      setLead({ ...lead, startDate: new Date().toISOString() })
    }
    if (!lead.endDate) {
      setLead({ ...lead, endDate: dayjs(Date()).add(1, 'day').toISOString() })
    }
  }, [lead])

  return (
    <div className="middle">
      <Stepper activeStep={2} divARef={divARef} divBRef={divBRef} steps={stepperSteps} />
      <div className="rht flex-0-a">
        <div className="lead-rht-inner">
          <div ref={divBRef} className="scroll-div">
            <h4 className="arrival-header primary-font fz-16 ttu fw-400">{t('arrivalDeparture')}</h4>
            <div className='header-button bdr-10'>
              <TransparentButton active={exactDate} icon={<ExactDateSvg />} label={t('exactDates')} onClick={() => setExactDate(true)} />
              <TransparentButton active={!exactDate} icon={<TentativeSvg />} label={t('tentativeDates')} onClick={() => setExactDate(false)} />
            </div>
            <div className="form-outer items-3 mb-40">
              {!exactDate
                ? (<div className="form-group">
                  <CustomAutocomplete
                    dropDownOpenLength={0}
                    helperText={t('fieldRequired')}
                    label={t('daysNights')}
                    defaultValue={getDefaultValueInDropdown(daysAndNights, lead.daysAndNights)}
                    options={daysAndNights}
                    onSelect={(value) => {
                      if (!value) return;
                      let newLead = lead;
                      newLead.daysAndNights = value?.label;
                      newLead.endDate = dayjs(newLead.startDate).add(value.id, 'day').toISOString()
                      setLead({ ...newLead });
                    }}
                  />
                </div>)
                : (undefined)}
              <div className="form-group">
                <CustomDateInput minDate={dayjs(new Date())} defaultValue={lead.startDate?.toString()} helperText={t('fieldRequired')} label={t('startDate')} onChange={(value) => {
                  if (!value) return;
                  let newLead = lead;
                  newLead.startDate = value;
                  setLead({ ...newLead });
                }} />
              </div>
              <div className="form-group">
                <CustomDateInput value={dayjs(lead.startDate).isAfter(dayjs(lead.endDate)) ? dayjs(lead.startDate) : dayjs(lead.endDate)} minDate={dayjs(lead.startDate)} defaultValue={lead.endDate?.toString()} helperText={t('fieldRequired')} label={t('endDate')} onChange={(value) => {
                  if (!value) return;
                  let newLead = lead;
                  newLead.endDate = value;
                  setLead({ ...newLead });
                }} />
              </div>
              {!exactDate
                ? (undefined)
                : (<div className="form-group"></div>)}
              <div className="form-group">
                <GoogleSearchBox size="small" required error={leadValidation.portArrival} helperText={t('fieldRequired')} label={t('portOfArrival')} defaultValue={lead.portArrival} onSelect={(value: any) => {
                  if (!value) return;
                  let newLead = lead;
                  newLead.portArrival = value?.formatted_address;
                  setLead({ ...newLead });
                }} />
              </div>
              <div className="form-group">
                <CustomSelect
                  helperText={t('fieldRequired')}
                  label={t('modeOfArrival')}
                  defaultValue={getDefaultValueInDropdown(modeOfArrival, lead.modeOfArrival)}
                  options={modeOfArrival}
                  onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead;
                    newLead.modeOfArrival = value?.label;
                    setLead({ ...newLead });
                  }}
                />
              </div>
              <div className="form-group">
                <FormControlLabel control={<Android12Switch defaultChecked={lead.departFromSame} />} label={t('departingFromSame')} onChange={(value: any) => {
                  if (!value) return;
                  let newLead = lead;
                  newLead.departFromSame = value.target.checked;
                  setLead({ ...newLead });
                }} />
              </div>
              <div className="form-group">
                <GoogleSearchBox size="small" disabled={lead.departFromSame} required error={leadValidation.portDeparture} helperText={t('fieldRequired')} label={t('portOfDeparture')} defaultValue={lead.portDeparture} onSelect={(value: any) => {
                  if (!value) return;
                  let newLead = lead;
                  newLead.portDeparture = value?.formatted_address;
                  setLead({ ...newLead });
                }} />
              </div>
              <div className="form-group">
                <CustomSelect
                  disabled={lead.departFromSame}
                  helperText={t('fieldRequired')}
                  label={t('modeOfDeparture')}
                  defaultValue={getDefaultValueInDropdown(modeOfDeparture, lead.modeOfDeparture)}
                  options={modeOfDeparture}
                  onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead;
                    newLead.modeOfDeparture = value?.label;
                    setLead({ ...newLead });
                  }}
                />
              </div>
            </div>
            <h4 className="header primary-font fz-16 ttu fw-400">{t('budgetGroup')}</h4>
            <div className="form-outer mb-40">
              <div className="form-group multiple-form-group">
                <span className="small-label">{t('budgetRange')}</span>
                <div className="form-outer">
                  <div className="form-group">
                    <CustomTextField defaultValue={lead.budgetRangeFrom} value={lead.budgetRangeFrom} label={t('from')} size="small" helperText={t('fieldRequired')}
                      type='number'
                      onChange={(val) => {
                        if (parseInt(val.target.value) < 0) return;
                        let newLead = lead;
                        newLead.budgetRangeFrom = val.target.value
                        setLead({ ...newLead });
                      }} />
                  </div>
                  <div className="form-group">
                    <CustomTextField defaultValue={lead.budgetRangeTo} value={lead.budgetRangeTo} label={t('to')} size="small" helperText={t('fieldRequired')}
                      type='number'
                      onChange={(val) => {
                        if (parseInt(val.target.value) < 0) return;
                        let newLead = lead;
                        newLead.budgetRangeTo = val.target.value
                        setLead({ ...newLead });
                      }} />
                  </div>
                </div>
              </div>
              <div className="form-group multiple-form-group">
                <span className="small-label">Travel Group</span>
                <div className="form-outer items-3">
                  <div className="form-group">
                    <CustomTextField defaultValue={lead.travelGroupAdults} value={lead.travelGroupAdults} label={t('adults')} size="small" helperText={t('fieldRequired')}
                      type='number'
                      onChange={(val) => {
                        if (parseInt(val.target.value) < 0) return;
                        let newLead = lead;
                        newLead.travelGroupAdults = val.target.value
                        setLead({ ...newLead });
                      }} />
                  </div>
                  <div className="form-group">
                    <CustomTextField defaultValue={lead.travelGroupChildren} value={lead.travelGroupChildren} label={t('children')} size="small" helperText={t('fieldRequired')}
                      type='number'
                      onChange={(val) => {
                        if (parseInt(val.target.value) < 0) return;
                        let newLead = lead;
                        newLead.travelGroupChildren = val.target.value
                        setLead({ ...newLead });
                      }} />
                  </div>
                  <div className="form-group">
                    <CustomTextField defaultValue={lead.travelGroupInfants} value={lead.travelGroupInfants} label={t('infants')} size="small" helperText={t('fieldRequired')}
                      type='number'
                      onChange={(val) => {
                        if (parseInt(val.target.value) < 0) return;
                        let newLead = lead;
                        newLead.travelGroupInfants = val.target.value
                        setLead({ ...newLead });
                      }} />
                  </div>
                </div>
              </div>
            </div>

            <h4 className="header primary-font fz-16 ttu fw-400">{t('visasTransportation')}</h4>
            <div className="form-outer">
              <div className="form-group">
                <CustomSelect
                  helperText={t('fieldRequired')}
                  label={t('visaRequirements')}
                  defaultValue={getDefaultValueInDropdown(visaRequirement, lead.visaRequirement)}
                  options={visaRequirement}
                  onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead;
                    newLead.visaRequirement = value?.label;
                    setLead({ ...newLead });
                  }}
                />
              </div>
              <div className="form-group">
                <CustomSelect
                  helperText={t('fieldRequired')}
                  label={t('arrivalDeparture')}
                  defaultValue={getDefaultValueInDropdown(arrivalDeparture, lead.arrivalDeparture)}
                  options={arrivalDeparture}
                  onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead;
                    newLead.arrivalDeparture = value?.label;
                    setLead({ ...newLead });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArrivalDeparture