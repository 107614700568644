import React, { useState } from 'react'
import AuthLayout from '../../components/AuthLayout/AuthLayout'
import SetNewPasswordForm from './SetNewPasswordForm'
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ForgotPasswordFunctions from '../ForgotPassword/forgotPasswordFunctions';
import { clearStorage } from '../../helpers/storageConfig';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { removeUser } from '../../store/slices/userSlice';

const SetNewPassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const forgotPasswordFunctions = new ForgotPasswordFunctions();
    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    const token = searchParams.get("token") ?? '';
    const email = searchParams.get("email") ?? '';
    const existingUser = (searchParams.get("existingUser") === '1');

    const handleSubmitNewPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if ((newPassword !== confirmPassword) && !existingUser) {
            return;
        }
        setLoading(true);
        try {
            await handleResetPassword();
        } catch (error) {
            console.error("Password reset failed", error);
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (): Promise<void> => {
        const payload = { customToken: token, password: confirmPassword, authenticatePassword: existingUser };

        return new Promise((resolve, reject) => {
            forgotPasswordFunctions.resetPassword(
                payload,
                setLoading,
                (response: any) => {
                    resolve(response);
                    navigate('/login');
                    clearStorage();
                    dispatch(removeUser());
                },
                (error: any) => {
                    console.error("Error resetting password", error);
                    reject(error);
                }
            );
        });
    };

    return (
        <AuthLayout contentData={{ title: t('welcomeToExplor'), description: 'Confirm password for connecting to the new organization', email }}>
            <SetNewPasswordForm
                newPassword={newPassword}
                confirmPassword={confirmPassword}
                loading={loading}
                onChangeNewPassword={(e) => setNewPassword(e.target.value)}
                onChangeConfirmPassword={(e) => setConfirmPassword(e.target.value)}
                onSubmit={handleSubmitNewPassword}
            />
        </AuthLayout>
    )
}

export default SetNewPassword