import { TravelExploreOutlined } from "@mui/icons-material";
import { Box, Tabs, Tab } from "@mui/material";
import { SectionTab } from "polotno/side-panel";
import BuilderPages from "./BuilderPages";
import BuilderTemplates from "./BuilderTemplates";
import { observer } from 'mobx-react-lite';
import BuilderFunctions from "../../BuilderFunctions";
import { useEffect, useState } from "react";
import { StoreType } from "polotno/model/store";
import { ItineraryDocument } from "../../../../../helpers/Interfaces";
import ApiService from "../../../../../services/ApiService";

const BuilderGlobal = observer(({ store }: { store: StoreType }) => {
    const [value, setValue] = useState(0);
    const [templates, setTemplates] = useState<ItineraryDocument[]>([]);
    const [pages, setPages] = useState<ItineraryDocument[]>([]);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [loadingPages, setLoadingPages] = useState(false);
    const builderFunction = new BuilderFunctions();
    const apiService = new ApiService();

    useEffect(() => {
        getAllTemplates();
        getAllPages();
    }, [])

    const getAllTemplates = () => {
        setLoadingTemplates(true);
        apiService.getGlobalTemplates().then((response) => {
            setTemplates(response.data.data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoadingTemplates(false);
        })
    }

    const getAllPages = () => {
        setLoadingPages(true);
        apiService.getGlobalPages().then((response) => {
            setPages(response.data.data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoadingPages(false);
        })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <div style={{ height: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Templates" {...builderFunction.a11yProps(0)} />
                    <Tab label="Pages" {...builderFunction.a11yProps(1)} />
                </Tabs>
            </Box>
            <builderFunction.CustomTabPanel value={value} index={0}>
                <BuilderTemplates store={store} templates={templates} loading={loadingTemplates} />
            </builderFunction.CustomTabPanel>
            <builderFunction.CustomTabPanel value={value} index={1}>
                <BuilderPages store={store} pages={pages} loading={loadingPages} />
            </builderFunction.CustomTabPanel>
        </div>
    )
});

export const GlobalSection = {
    name: 'Global',
    Tab: (props: any) => (
        <SectionTab name="Global" {...props}>
            <TravelExploreOutlined />
        </SectionTab>
    ),
    Panel: BuilderGlobal
}; 