import { ExportOptions, StoreType } from "polotno/model/store";
import FirebaseFunctions from "../../../helpers/firebaseFunctions";
import { ItineraryDocument } from "../../../helpers/Interfaces";
import ApiService from "../../../services/ApiService";
import { initialItineraryState, setItinerary } from "../../../store/slices/itinerarySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { emptyTemplate } from "../../../helpers/constants";
import { CSSProperties } from "react";
import { Box } from "@mui/material";
import { decompressData, generateUniqueKey } from "../../../helpers/helperfunctions";


type saveDocProps = {
    publish?: boolean, setLoading: CallableFunction, loadJson: any
    loading: any, store: StoreType, itinerary: ItineraryDocument, pdfViewer: any
};

type publishDocProps = {
    setPdfViewer: CallableFunction, setLoading: CallableFunction,
    loading: any, store: StoreType, itinerary: ItineraryDocument,
};

type TabPanelProps = {
    children?: React.ReactNode; index: number; value: number;
}

type AddPageOrTemplate = {
    store: StoreType;
    itinerary: ItineraryDocument
}


export default class BuilderFunctions {
    firebaseFunction = new FirebaseFunctions();
    apiService = new ApiService();
    dispatch = useDispatch<AppDispatch>();
    navigate = useNavigate();

    handleSaveDoc = async (props: saveDocProps) => {
        props.setLoading({ ...props.loading, [props.publish ? 'submit' : 'save']: true });

        try {
            await new Promise(resolve => setTimeout(resolve, 100));

            const previewBlob = await props.store.toBlob({ pageId: props.store.pages[0].id });
            const previewUrl = await this.firebaseFunction.savePreviewFirebase(previewBlob as Blob, props.itinerary.documentId ?? '');

            const documentContent = props.store.toJSON();
            const document: ItineraryDocument = {
                ...props.itinerary,
                previewUrl,
                documentContent,
                modifiedAt: new Date().toISOString(),
                pdfUrl: props.publish ? props.pdfViewer.url : (props.itinerary.pdfUrl ?? ''),
                publish: props.publish,
                revisions: props.itinerary.publish ? (props.itinerary.revisions || 0) + 1 : props.itinerary.revisions ?? 0,
            };

            await this.firebaseFunction.updateItineraryDocument(props.itinerary.documentId ?? '', document);

            if (props.publish) {
                this.dispatch(setItinerary(initialItineraryState));
                props.loadJson(emptyTemplate);
                this.navigate('/dashboard/lead-tracker');
            }

            props.setLoading({ submit: false, save: false });
        } catch (error) {
            console.error('Error saving document:', error);
            props.setLoading({ submit: false, save: false });
        }
    };

    handlePublish = (props: publishDocProps) => async () => {
        try {
            props.setLoading({ ...props.loading, submit: true })
            const options: ExportOptions = {}
            const url = await props.store.toPDFDataURL(options);

            const pdfUrl = await this.firebaseFunction.uploadBase64AsPDF(url, props.itinerary.documentId ?? '');
            props.setLoading({ submit: false, save: false });

            if (!pdfUrl) return

            props.setPdfViewer({ open: true, url: pdfUrl })
        } catch (e) {
            console.log(e);
            props.setLoading({ submit: false, save: false });
        }
    }

    builderVisibility = (itinerary: ItineraryDocument): CSSProperties | undefined => {
        if (!itinerary.documentId) {
            return { pointerEvents: 'none', opacity: 0.5 };
        } else {
            return { pointerEvents: 'initial', opacity: 1 };
        }
    };

    a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    addDocument = (props: AddPageOrTemplate) => {
        const decompressed = decompressData(props.itinerary.documentContent)

        if (props.itinerary.documentType === 'page') {
            const replaceIdsRecursively = (data: any): any => {
                const uid = generateUniqueKey();
                return {
                    ...data,
                    id: data.id + uid,
                    children: data.children?.map((child: any) => replaceIdsRecursively(child)) || [] // Recursively replace IDs in children
                };
            };

            const newObject = replaceIdsRecursively(decompressed);

            props.store.addPage(newObject)
        } else {
            props.store.loadJSON(decompressed);
        }
    }

    CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
            </div>
        );
    }

    savePage = async (store: StoreType, itineraryData: ItineraryDocument, documentId: string) => {
        const selectedPageId = store._activePageId;
        const previewBlob = await store.toBlob({ pageId: selectedPageId });
        const previewUrl = await this.firebaseFunction.savePreviewFirebase(previewBlob as Blob, documentId);

        const documentContent = store.pages.find((page: any) => (page.id === selectedPageId))?.toJSON();

        const data = { ...itineraryData, previewUrl, documentContent, createdAt: new Date().toISOString() };
        delete data.documentId;

        await this.firebaseFunction.updateItineraryDocument(documentId, data);
    }
}

