import { TextField, TextFieldProps } from "@mui/material";
import './CustomTextField.scss';
import '../MuiBaseStyle.scss'


export default function CustomTextField(props: TextFieldProps) {
    return (
        <TextField classes={{ root: 'custom-mui-field custom-text-field ' }} fullWidth={props.fullWidth !== false} required={props.required} select={props.select} defaultValue={props.defaultValue} variant='outlined' margin='normal'
            helperText={props.error ? (props.helperText ?? 'Required') : null} size={props.size} placeholder={props.placeholder} label={props.label} type={props.type} value={props.value} disabled={props.disabled}
            error={props.error} onChange={props.onChange} onFocus={props.onFocus} onBlur={props.onBlur} inputProps={props.inputProps} InputProps={props.InputProps} multiline={props.multiline} rows={props.rows} >
            {props.children}
        </TextField>
    );
}