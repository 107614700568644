import { useDispatch } from "react-redux";
import FirebaseFunctions from "../../../helpers/firebaseFunctions";
import { ItineraryDocument } from "../../../helpers/Interfaces";
import ApiService from "../../../services/ApiService";
import { AppDispatch } from "../../../store/store";
import { showAlert } from "../../../store/slices/alertSlice";

export class LibraryFunctions {
    apiService = new ApiService();
    firebaseFunctions = new FirebaseFunctions();
    dispatch = useDispatch<AppDispatch>();

    getAllTemplates = (setTemplates: CallableFunction, setLoading: CallableFunction) => {
        setLoading(true)
        this.apiService.getTemplates({ contentRequired: false, publicationStatus: false }).then((response) => {
            setTemplates(response.data.data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    getAllPages = (setPages: CallableFunction, setLoading: CallableFunction) => {
        setLoading(true)
        this.apiService.getItineraryPages({ contentRequired: false }).then((response) => {
            setPages(response.data.data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    deleteItineraryDocument = async (documentId: string, documentType: 'itineraries' | 'templates' | 'pages') => {
        this.apiService.deleteItineraryDocument({ documentId, documentType }).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error);
            this.dispatch(showAlert({ message: 'Delete Itinerary Document Failed!', severity: 'error' }))
        });
    }

    editTemplate = async (itinerary?: ItineraryDocument) => {
        if (!itinerary) return;
        return await this.firebaseFunctions.getItineraryDocument(itinerary.documentId ?? '', itinerary.documentType);
    }
}