import React, { useEffect, useState } from 'react'
import { ICreateLeadData, IItinerary, IStepperItem, ItineraryDocument } from '../../helpers/Interfaces';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import Stepper from '../Stepper/Stepper';
import Itinerary from '../../assets/images/lead-card/ItineraryFilter.svg'
import Dropdown from '../Builder/DropDown/DropDown';
import ItineraryCard from '../ItineraryCard/ItineraryCard';
import LeadTrackerFunctions from "../../pages/Dashboard/LeadTracker/LeadFunctions";
import Evidence from '../../assets/images/newLead/evidence.gif'

type GeneralItineraryProps = {
    divARef: React.MutableRefObject<HTMLDivElement | null>
    divBRef: React.MutableRefObject<HTMLDivElement | null>
    stepperSteps: IStepperItem[],
    lead: ICreateLeadData,
    setLead: CallableFunction,
    selectedTemplate?: ItineraryDocument,
    setSelectedTemplate: CallableFunction
}

const GeneralItinerary = ({ divARef, divBRef, stepperSteps, lead, setLead, setSelectedTemplate, selectedTemplate }: GeneralItineraryProps) => {
    const trackerFunctions = new LeadTrackerFunctions();
    const [templates, setTemplates] = useState<ItineraryDocument[]>([]);
    const [templatesLoading, setTemplatesLoading] = useState<boolean>(false);

    useEffect(() => {
        trackerFunctions.getAllTemplates(setTemplates, setTemplatesLoading);
    }, [])

    const handleSelectTemplate = (item: ItineraryDocument) => {

        if (selectedTemplate?.documentId === item.documentId) {
            setSelectedTemplate(undefined);
        } else {
            setSelectedTemplate(item)
        }
    }

    return (
        <div className="middle">
            <Stepper activeStep={5} divARef={divARef} divBRef={divBRef} steps={stepperSteps} />
            <div className="rht flex-0-a">
                <div className="lead-rht-inner">
                    <div ref={divBRef} className="scroll-div itinerary-outer">
                        <div className="itinerary-inner">
                            <span className="primary-font">SELECT A SAVED ITINERARY & START CUSTOMIZING</span>
                            <Dropdown icon={<img src={Itinerary} />} searchable options={[]} onSelect={() => { }} />
                            <div className="itinerary-scroll">
                                {templatesLoading
                                    ? (<>
                                        <Skeleton width={'392px'} height={'102px'} variant='rounded' />
                                    </>)
                                    : (templates.length > 0
                                        ? (templates?.map(item => <ItineraryCard selected={selectedTemplate?.documentId === item?.documentId} itinerary={item} onClick={handleSelectTemplate} />))
                                        : (<div className="primary-font">No Itineraries found</div>))}
                            </div>
                        </div>
                        <div className="itinerary-map">
                            {(selectedTemplate)
                                ? (<embed className='pdf-viewer' src={`${selectedTemplate.pdfUrl}#toolbar=0`} type="application/pdf" />)
                                : (<div className='empty-pdf-view'>
                                    <img src={Evidence} alt="" />
                                    <div className="header primary-font">Nothing to Preview</div>
                                    <div className="description primary-font">Select a saved itinerary to preview it here, or you can create one from scratch</div>
                                </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralItinerary