import { DropdownItemProps } from "../../../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { ICreateLeadData, ICreateLeadUser, ILead, ItineraryDocument } from "../../../helpers/Interfaces";
import ApiService from "../../../services/ApiService";
import { sortLeads } from "../../../helpers/helperfunctions";
import FirebaseFunctions from "../../../helpers/firebaseFunctions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { showAlert } from "../../../store/slices/alertSlice";

class LeadFunctions {
    apiService = new ApiService();
    firebaseFunction = new FirebaseFunctions();
    dispatch = useDispatch<AppDispatch>();

    createLead = async (lead: ICreateLeadData, customer: ICreateLeadUser, setLoading: CallableFunction, refreshFunction?: CallableFunction) => {
        const createLeadPromise = new Promise((resolve, reject) => {
            setLoading(true);
            this.apiService.createLead({
                lead, customer
            }).then((response) => {
                if (refreshFunction)
                    refreshFunction();
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error)
                this.dispatch(showAlert({ message: 'Create Lead Failed!', severity: 'error' }))
            }).finally(() => {
                setLoading(false);
            });
        })

        return createLeadPromise;
    }

    updateLead = async (lead: ICreateLeadData, customer: ICreateLeadUser, setLoading: CallableFunction, refreshFunction?: CallableFunction) => {
        const task = lead.task?.map(task => task.id);
        const updateClientPromise = new Promise((resolve, reject) => {
            setLoading(true);
            this.apiService.updateLead({ lead : {...lead, task}, customer })
                .then((response) => {
                    if (refreshFunction)
                        refreshFunction();
                    resolve(response);
                }).catch((error) => {
                    console.log(error);
                    reject(error)
                    this.dispatch(showAlert({ message: 'Update Lead Failed!', severity: 'error' }))
                }).finally(() => {
                    setLoading(false);
                });
        })

        return updateClientPromise;
    }

    createClient = async (organizationUser: ICreateLeadUser, setLoading: CallableFunction, refreshFunction?: CallableFunction) => {
        setLoading(true);
        const createClientPromise = new Promise((resolve, reject) => {
            this.apiService.createClient({
                organizationUser
            }).then((response) => {
                if (refreshFunction)
                    refreshFunction();
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error)
                this.dispatch(showAlert({ message: 'Create Client Failed!', severity: 'error' }))
            }).finally(() => {
                setLoading(false);
            })
        })

        return createClientPromise;
    }

    getAllLeads = (setLeads: CallableFunction, setLoading?: CallableFunction) => {
        if (setLoading) setLoading(true)
        this.apiService.getAllLeads().then((response) => {
            const leads = sortLeads(response.data.data as ILead[])
            setLeads(leads);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            if (setLoading) setLoading(false)
        })
    }

    getAllOrganizationUsers = (setUsers: CallableFunction) => {
        this.apiService.getAllOrganizationUsers().then((response) => {
            const users = response.data.customers;
            setUsers(users);
        }).catch((error) => {
            console.log(error);
            setUsers([]);
        }).catch((error) => console.log(error))
    }

    getAllUser = (setUsers: CallableFunction) => {
        try {
            this.apiService.getAllUsers().then((response) => {
                let users = response.data.users as DropdownItemProps[];
                return setUsers(users);
            });
        } catch (error) {
            setUsers([])
        }
    }

    getAllTemplates = (setItineraries: CallableFunction, setItineraryLoading: CallableFunction) => {
        setItineraryLoading(true);
        this.apiService.getTemplates({ contentRequired: false, publicationStatus: true }).then((response) => {
            let itineraries = response.data.data;
            if (itineraries) {
                setItineraries(itineraries);
            } else {
                setItineraries([])
            }
            return setItineraryLoading(false);
        }).catch(() => {
            setItineraries({ draft: [], sent: [] });
        }).finally(() => {
            setItineraryLoading(false);
        });
    }

    createItinerary = async (leadId: string, itinerary?: ItineraryDocument) => {
        const data = {
            leadId, itinerary
        };
        const createItinerary = new Promise((resolve, reject) => {
            this.apiService.createLeadItineraries(data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                resolve(undefined)
                this.dispatch(showAlert({ message: 'Create Itinery Failed!', severity: 'error' }))
            })
        })

        return createItinerary;
    }

    getDocumentContent = async (itinerary: ItineraryDocument) => {
        return await this.firebaseFunction.getItineraryDocument(itinerary?.documentId ?? '', itinerary.documentType);
    }

    async getLeadById(id: string) {
        const response = await this.apiService.getLeadById({ leadId: id });
        return { id, ...response.data.lead };
    }
}

export default LeadFunctions;