const CalenderOrangeSvg = ({ size }: { size?: number }) => {
    return (
        <svg width={`${size ?? 10}`} height={`${size ?? 10}`} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8431_5891)">
                <path d="M8.70864 0.585938H7.82629V1.46829C7.82629 1.64476 7.67923 1.76241 7.53217 1.76241C7.38511 1.76241 7.23805 1.64476 7.23805 1.46829V0.585938H2.53217V1.46829C2.53217 1.64476 2.38511 1.76241 2.23805 1.76241C2.09099 1.76241 1.94393 1.64476 1.94393 1.46829V0.585938H1.06158C0.620404 0.585938 0.296875 0.96829 0.296875 1.46829V2.52711H9.70864V1.46829C9.70864 0.96829 9.17923 0.585938 8.70864 0.585938ZM0.296875 3.14476V8.52711C0.296875 9.05653 0.620404 9.40947 1.09099 9.40947H8.73805C9.20864 9.40947 9.73805 9.02711 9.73805 8.52711V3.14476H0.296875ZM2.91452 8.08594H2.20864C2.09099 8.08594 1.97335 7.9977 1.97335 7.85064V7.11535C1.97335 6.9977 2.06158 6.88005 2.20864 6.88005H2.94393C3.06158 6.88005 3.17923 6.96829 3.17923 7.11535V7.85064C3.14982 7.9977 3.06158 8.08594 2.91452 8.08594ZM2.91452 5.43888H2.20864C2.09099 5.43888 1.97335 5.35064 1.97335 5.20358V4.46829C1.97335 4.35064 2.06158 4.233 2.20864 4.233H2.94393C3.06158 4.233 3.17923 4.32123 3.17923 4.46829V5.20358C3.14982 5.35064 3.06158 5.43888 2.91452 5.43888ZM5.26746 8.08594H4.53217C4.41452 8.08594 4.29688 7.9977 4.29688 7.85064V7.11535C4.29688 6.9977 4.38511 6.88005 4.53217 6.88005H5.26746C5.38511 6.88005 5.50276 6.96829 5.50276 7.11535V7.85064C5.50276 7.9977 5.41452 8.08594 5.26746 8.08594ZM5.26746 5.43888H4.53217C4.41452 5.43888 4.29688 5.35064 4.29688 5.20358V4.46829C4.29688 4.35064 4.38511 4.233 4.53217 4.233H5.26746C5.38511 4.233 5.50276 4.32123 5.50276 4.46829V5.20358C5.50276 5.35064 5.41452 5.43888 5.26746 5.43888ZM7.6204 8.08594H6.88511C6.76746 8.08594 6.64982 7.9977 6.64982 7.85064V7.11535C6.64982 6.9977 6.73805 6.88005 6.88511 6.88005H7.6204C7.73805 6.88005 7.8557 6.96829 7.8557 7.11535V7.85064C7.8557 7.9977 7.76746 8.08594 7.6204 8.08594ZM7.6204 5.43888H6.88511C6.76746 5.43888 6.64982 5.35064 6.64982 5.20358V4.46829C6.64982 4.35064 6.73805 4.233 6.88511 4.233H7.6204C7.73805 4.233 7.8557 4.32123 7.8557 4.46829V5.20358C7.8557 5.35064 7.76746 5.43888 7.6204 5.43888Z" fill="#E27C37" />
            </g>
            <defs>
                <clipPath id="clip0_8431_5891">
                    <rect width="10" height="10" fill="white" />
                </clipPath>
            </defs>
        </svg>)
}

export default CalenderOrangeSvg