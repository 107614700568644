import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptyTemplate } from "../../helpers/constants";
import { ItineraryDocument } from "../../helpers/Interfaces";

export const initialItineraryState: ItineraryDocument = { documentType: 'itinerary', documentId: undefined, documentContent: emptyTemplate };

export const userSlice = createSlice({
    name: 'itinerary',
    initialState: initialItineraryState,
    reducers: {
        setItinerary: (state, action: PayloadAction<ItineraryDocument>) => {
            return action.payload;
        },
        removeItineraryId: () => {
            return initialItineraryState;
        }
    }
});

export const { setItinerary, removeItineraryId } = userSlice.actions;
export default userSlice.reducer;