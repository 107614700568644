import Dashboard from '../../../components/Dashboard/Dashboard'
import TransparentButton from '../../../components/TransparentButton/TransparentButton'
import BoardSvg from '../../../assets/images/buttons/BoardSvg'
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton'
import PluseSvg from '../../../assets/images/buttons/PluseSvg'
import { t } from "i18next";
import ListSvg from '../../../assets/images/buttons/ListSvg'
import "./Library.scss"
import { useEffect, useState } from 'react'
import CreateTemplate from '../../../components/CreateTemplate/CreateTemplate'
import { LibraryFunctions } from './LibraryFunctions'
import { ItineraryDocument } from '../../../helpers/Interfaces'
import TemplateCard from '../../../components/ItineraryCard/TemplateCard'
import { Box, Skeleton } from '@mui/material'
import TemplatePreview from '../../../components/Builder/TemplatePreview/TemplatePreview'

const Library = () => {
    const [libraryView, setLibraryView] = useState<'template' | 'page'>('template');
    const [createTemplate, setCreateTemplate] = useState<boolean>(false);
    const [vewTemplate, setVewTemplate] = useState<boolean>(false);
    const [templates, setTemplates] = useState<ItineraryDocument[]>([]);
    const [pages, setPages] = useState<ItineraryDocument[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<ItineraryDocument>();
    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
    const [loadingPages, setLoadingPages] = useState<boolean>(false);
    const libraryFunction = new LibraryFunctions()

    useEffect(() => {
        getTemplates();
    }, [])

    const handleAddNew = () => {
        setCreateTemplate(true)
    }

    const getTemplates = () => {
        libraryFunction.getAllTemplates(setTemplates, setLoadingTemplates)

        libraryFunction.getAllPages(setPages, setLoadingPages)
    }

    const deleteTemplate = async () => {
        closeViewTemplate();
        await libraryFunction.deleteItineraryDocument(selectedTemplate?.documentId ?? '', selectedTemplate?.documentType == 'template' ? 'templates' : 'pages');;
        setTimeout(getTemplates, 300);

    }

    const getLibraryView = () => {
        if (libraryView === 'template') {
            return (
                <div className='template-container'>
                    {loadingTemplates
                        ? Array.from({ length: 3 }, (_, index) => (
                            <Box minWidth={'302px'} >
                                <Skeleton variant="rounded" height={'318px'} sx={{ marginBottom: 'clamp(0.75rem, 0.78125vw, 0.9375rem)', borderRadius: '10px' }} />
                            </Box>
                        ))
                        : (templates.map((template) => (<TemplateCard onClick={handleViewTemplate} itinerary={template} />)))}
                </div>
            )
        } else if (libraryView === 'page') {
            return (
                <div className='template-container'>
                    {loadingPages
                        ? Array.from({ length: 3 }, (_, index) => (
                            <Box minWidth={'302px'} >
                                <Skeleton variant="rounded" height={'318px'} sx={{ marginBottom: 'clamp(0.75rem, 0.78125vw, 0.9375rem)', borderRadius: '10px' }} />
                            </Box>
                        ))
                        : (pages.map((template) => (<TemplateCard onClick={handleViewTemplate} itinerary={template} />)))}
                </div>
            )
        }
    }

    const handleViewTemplate = (template: ItineraryDocument) => {
        setSelectedTemplate(template);
        setVewTemplate(true);
    }

    const closeCreateTemplate = () => setCreateTemplate(false);
    const closeViewTemplate = () => setVewTemplate(false);

    return (
        <Dashboard headerTitle={t('library')}>
            <div className='library-container'>
                <div className="content-top">
                    <div className="lft">
                        <div className="lft-inner">
                            <div className="view-styles bdr-10">
                                <TransparentButton active={libraryView === 'template'} icon={<BoardSvg />} label={t("templates")} onClick={() => setLibraryView('template')} />
                                <TransparentButton active={libraryView === 'page'} icon={<ListSvg />} label={t("pages")} onClick={() => setLibraryView('page')} />
                            </div>
                        </div>
                    </div>
                    <div className="rht">
                        <div className="content-right">
                            {/* <div className="filter-outer">
                                <span>
                                    {false ? <UserFilterRemove /> : <UserFilter />}
                                </span>
                                <span>
                                    {false ? <FilterIconRemove /> : <FilterIcon />}
                                </span>
                            </div> */}
                            {libraryView === 'template' ? (<SecondaryButton label={t('addNew')} icon={<PluseSvg />} onClick={handleAddNew} />) : (<></>)}
                        </div>
                    </div>
                </div>
                <div className="library-outer">
                    {getLibraryView()}
                </div>
                <CreateTemplate open={createTemplate} close={closeCreateTemplate} />
                <TemplatePreview open={vewTemplate} close={closeViewTemplate} itinerary={selectedTemplate} clone={() => { }} delete={deleteTemplate} />
            </div>
        </Dashboard>
    )
}

export default Library