import React, { useState, useEffect } from "react";
import "./FileTable.scss";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import { ICustomFile } from "../../helpers/Interfaces";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { bytesToMegabytes, downloadPdf, getFileExtension } from "../../helpers/helperfunctions";
import { format } from "date-fns";
import FileIcon from '@mui/icons-material/InsertDriveFile';
import FirebaseFunctions from "../../helpers/firebaseFunctions";
import PdfIcon from "../../assets/images/profile/PdfIcon";
import DocIcon from "../../assets/images/profile/DocIcon";
import TxtIcon from "../../assets/images/profile/TxtIcon";
interface FileTableProps {
    fileList?: ICustomFile[];
    setFileList: React.Dispatch<React.SetStateAction<ICustomFile[]>>;
    onDelete: (id: string) => void;
    setLoading: CallableFunction;
}
const FileTable: React.FC<FileTableProps> = ({ fileList, setFileList, onDelete, setLoading }) => {
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const firbaseBaseFunction = new FirebaseFunctions();
    useEffect(() => {
        if (fileList) {
            setSelectAll(selectedFiles!.length === fileList!.length && fileList!.length > 0);
        }
    }, [selectedFiles, fileList]);
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(fileList!.map(file => file.name));
        }
        setSelectAll(!selectAll);
    };
    const handleSelectFile = (fileName: string) => {
        setSelectedFiles(prevSelected =>
            prevSelected.includes(fileName)
                ? prevSelected.filter(name => name !== fileName)
                : [...prevSelected, fileName]
        );
    };
    const handleDelete = (fileId: string) => {
        firbaseBaseFunction.deleteMediaFirebase(fileId);
        // setFileList(prevFiles => {
        //     if (prevFiles) {
        //         return prevFiles.filter(file => file.name !== fileName);
        //     }
        //     return [];
        // });
        setSelectedFiles(prevSelected => {
            if (prevSelected) {
                return prevSelected.filter(name => name !== fileId);
            }
            return [];
        });
        onDelete(fileId);
    };
    return (
        fileList!.length > 0 ?
            <div className="file-table">
                <div className="file-table-header">
                    <div className="file-column-header">
                        {/* <input type="checkbox" checked={selectAll} onChange={handleSelectAll} /> */}
                    </div>
                    <div className="file-column-header">NAME</div>
                    <div className="file-column-header">TYPE</div>
                    <div className="file-column-header">SIZE</div>
                    <div className="file-column-header">LAST MODIFIED</div>
                    <div className="file-column-header" style={{ justifyContent: "end" }}><MoreVertIcon /></div>
                </div>
                {fileList!.map((file, index) => (
                    <div key={index} className="file-table-row">
                        <div className="file-column">
                            {/* <input
                                type="checkbox"
                                checked={selectedFiles.includes(file.name)}
                                onChange={() => handleSelectFile(file.name)}
                            /> */}
                        </div>
                        <div className="file-column">{file.name}</div>
                        <div className="file-column">
                            {getFileExtension(file.name) === "pdf" ? <PdfIcon /> : getFileExtension(file.name) === "doc" ? <DocIcon /> : getFileExtension(file.name) === "txt" ? <TxtIcon /> : <FileIcon />}
                        </div>
                        <div className="file-column">{bytesToMegabytes(file.size) + "MB"}</div>
                        <div className="file-column">{format(file.lastModified, "MMM. d, yyyy")}</div>
                        <div className="file-column">
                            <div onClick={() => {
                                downloadPdf(file.downloadLink ? file.downloadLink : '', file.name, setLoading)
                            }} >
                                <DownloadIcon className="action-icon" sx={{ height: '15px', width: '15px' }} />
                            </div>
                            <div onClick={() => handleDelete(file.id)}>
                                <DeleteIcon className="action-icon" sx={{ height: '15px', width: '15px' }} />
                            </div>
                        </div>
                    </div>
                ))}
            </div> : <div className="no-files"><span> No files to Show</span></div>
    );
};
export default FileTable;