import React, { ReactElement } from 'react';
import logoDark from '../../assets/images/logo-dark.svg';
import CustomCarousel from '../CustomCarousel/CustomCarousel';
import "./AuthLayout.scss";

interface AuthLayoutProps {
    contentData: { title: string, description: string, element?: ReactElement, email?: string }
    children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ contentData, children }) => {
    return (
        <div className='auth-layout-outer'>
            <div className='lft carousel-outer'>
                <CustomCarousel />
            </div>
            <div className='rht'>
                <div className='top'>
                    <img src={logoDark} alt='logo' className='logo' />
                    <h3 className='fz-32 primary-font fw-400 little'> {contentData.title} </h3>
                    <div className='email-row'>
                        <p className='fz-20 body-font fw-400'> {contentData.description} <span className='email-span'>{contentData.email}</span></p><span>{contentData.element ?? ""}</span>
                    </div>
                    <div className='auth-form'>
                        {children}
                    </div>
                </div>
                <div className='bottom'>
                    <p className='mb-0 fz-14'>
                        By using Explor.App, you agree to our <span className='underline'> Terms and Conditions </span> and <span className='underline'> Privacy Policy </span>. Please <span className='underline'> Contact Support </span> for any assistance.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default AuthLayout;