import React from 'react'
import "./FileTypeIcon.scss"
import PdfIcon from '../../assets/images/profile/PdfIcon'
import DocIcon from '../../assets/images/profile/DocIcon'
import TxtIcon from '../../assets/images/profile/TxtIcon'
import { format } from 'date-fns'
import FileIcon from '@mui/icons-material/InsertDriveFile';


type FileTypeIconProps = {
    fileType: string;
    fileName: string;
    date: string;
    size: string;
};

const FileTypeIcon: React.FC<FileTypeIconProps> = ({ fileType, fileName, date, size }) => {
    return (
        <div className='file-container'>
            <div className={fileType === 'pdf' ? 'pdf-icon' : fileType === 'doc' || 'docx' ? 'doc-icon' : fileType === 'txt' ? 'txt-icon' : 'txt-icon'}>
                {fileType === "pdf" ? <PdfIcon /> : fileType === 'doc' ? <DocIcon /> : fileType === 'txt' ? <TxtIcon /> : <FileIcon />}
            </div>
            <div className='details-column'>
                <span className='file-name'>{fileName}</span>
                <div className='date-size'>
                    <span className='date'>{format(date, 'MMM dd, yyyy')}</span>
                    <span className='date'>{size} MB</span>
                </div>
            </div>
        </div>
    )
}

export default FileTypeIcon