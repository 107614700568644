import AuthService from "../../services/AuthService";

export default class ForgotPasswordFunctions{
    authService = new AuthService();

    sendOtp = (data:any,setLoading:CallableFunction,onSuccess:CallableFunction,onError:CallableFunction) => {
        setLoading(true);
        this.authService.sendOtp(data)
            .then((response) => {
                onSuccess(response.data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    verifyOtp = (data:any,setLoading:CallableFunction,onSuccess:CallableFunction,onError:CallableFunction) => {
        setLoading(true);
        this.authService.verifyOtp(data)
            .then((response) => {
                onSuccess(response.data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    resetPassword = (data:any,setLoading:CallableFunction,onSuccess:CallableFunction,onError:CallableFunction) => {
        setLoading(true);
        this.authService.resetPassword(data)
            .then((response) => {
                onSuccess(response.data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }


}