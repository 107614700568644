import AuthService from '../../services/AuthService'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../store/slices/alertSlice';

export default class LoginFunctions {
    authService = new AuthService();
    apiService = new ApiService();

    userLogin = async (data: any, setLoading: CallableFunction, dispatch: any) => {
        setLoading(true);
        return await this.authService.userLogin(data).then((response) => response.data.user)
            .catch(() => {
                dispatch(showAlert({ message: 'Login failed', severity: 'error' }));
            }).finally(() => {
                setLoading(false);
            })
    }

    getAllTenants = (setLoading: CallableFunction, setOrganizations: CallableFunction) => {
        setLoading(true);
        this.apiService.getAllOrganizations().then((response) => {
            setOrganizations(response.data.data)
        }).catch(() => {

        }).finally(() => {
            setLoading(false);
        })
    }
}