import createStore from "polotno/model/store";
import { createContext, useEffect, useState } from "react";
import { emptyTemplate } from "../helpers/constants";

export const Store = createContext({} as any);

export default function PolontoStore({ children }: any) {

    const store = createStore({
        key: process.env.POLONTO_KEY ?? '', showCredit: true
    });

    const loadJson = (json: any) => {
        store.loadJSON(json)
    }

    const loadPage = (json: any) => {
        const polontoJson = { ...emptyTemplate, pages: [json] }
        store.loadJSON(polontoJson)
    }

    return (
        <Store.Provider value={[store, loadJson, loadPage]}>
            {children}
        </Store.Provider>
    );
}
