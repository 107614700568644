const ListSvg = () => (<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_619_553)">
        <path d="M5.51777 8.90625H1.35723C0.608828 8.90625 0 8.29742 0 7.54902V3.38848C0 2.64008 0.608828 2.03125 1.35723 2.03125H5.51777C6.26617 2.03125 6.875 2.64008 6.875 3.38848V7.54902C6.875 8.29742 6.26617 8.90625 5.51777 8.90625ZM1.35723 3.28125C1.29809 3.28125 1.25 3.32934 1.25 3.38848V7.54902C1.25 7.60816 1.29809 7.65625 1.35723 7.65625H5.51777C5.57691 7.65625 5.625 7.60816 5.625 7.54902V3.38848C5.625 3.32934 5.57691 3.28125 5.51777 3.28125H1.35723Z" fill="#134B5F" />
        <path d="M5.51777 17.9688H1.35723C0.608828 17.9688 0 17.3599 0 16.6115V12.451C0 11.7026 0.608828 11.0938 1.35723 11.0938H5.51777C6.26617 11.0938 6.875 11.7026 6.875 12.451V16.6115C6.875 17.3599 6.26617 17.9688 5.51777 17.9688ZM1.35723 12.3438C1.29809 12.3438 1.25 12.3918 1.25 12.451V16.6115C1.25 16.6707 1.29809 16.7188 1.35723 16.7188H5.51777C5.57691 16.7188 5.625 16.6707 5.625 16.6115V12.451C5.625 12.3918 5.57691 12.3438 5.51777 12.3438H1.35723Z" fill="#134B5F" />
        <path d="M19.375 5.15625H9.375C9.02984 5.15625 8.75 4.87641 8.75 4.53125C8.75 4.18609 9.02984 3.90625 9.375 3.90625H19.375C19.7202 3.90625 20 4.18609 20 4.53125C20 4.87641 19.7202 5.15625 19.375 5.15625Z" fill="#134B5F" />
        <path d="M15.9375 7.5H9.375C9.02984 7.5 8.75 7.22016 8.75 6.875C8.75 6.52984 9.02984 6.25 9.375 6.25H15.9375C16.2827 6.25 16.5625 6.52984 16.5625 6.875C16.5625 7.22016 16.2827 7.5 15.9375 7.5Z" fill="#134B5F" />
        <path d="M19.375 14.0625H9.375C9.02984 14.0625 8.75 13.7827 8.75 13.4375C8.75 13.0923 9.02984 12.8125 9.375 12.8125H19.375C19.7202 12.8125 20 13.0923 20 13.4375C20 13.7827 19.7202 14.0625 19.375 14.0625Z" fill="#134B5F" />
        <path d="M15.9375 16.5625H9.375C9.02984 16.5625 8.75 16.2827 8.75 15.9375C8.75 15.5923 9.02984 15.3125 9.375 15.3125H15.9375C16.2827 15.3125 16.5625 15.5923 16.5625 15.9375C16.5625 16.2827 16.2827 16.5625 15.9375 16.5625Z" fill="#134B5F" />
    </g>
    <defs>
        <clipPath id="clip0_619_553">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default ListSvg