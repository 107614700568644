import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { Search } from "@mui/icons-material";
import "./CustomAutocomplete.scss";
import "../MuiBaseStyle.scss";
import { t } from "i18next";
import { ReactElement, useState, useMemo, useEffect } from "react";

export type DropdownItemProps = {
  label: string;
  id: string | number;
  icon?: ReactElement;
};

const filter = createFilterOptions<DropdownItemProps>();

export default function CustomAutocomplete({
  options,
  label,
  onSelect,
  freeSolo = false,
  defaultValue,
  required = false,
  helperText,
  error = false,
  disabled = false,
  dropDownOpenLength = 3,
}: {
  options: DropdownItemProps[];
  label: string;
  onSelect: (value: DropdownItemProps | undefined | any) => void;
  freeSolo?: boolean;
  defaultValue?: DropdownItemProps;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  dropDownOpenLength?: number;
}) {
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [_freeSolo, setFreeSolo] = useState<boolean>(freeSolo);

  const translatedOptions = options.map((item) => ({ ...item, label: t(item.label) }));

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    const isExisting = translatedOptions.some((option) =>
      option.label.toLowerCase().includes(newInputValue.toLowerCase())
    );

    setInputValue(newInputValue);

    if (newInputValue.length >= dropDownOpenLength && isExisting) {
      setIsOpen(true);
    } else {
      setFreeSolo(true);
      onSelect({ id: "", label: newInputValue });
      setIsOpen(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, value: any) => {
    onSelect(value ?? undefined);
    setIsOpen(false);
  };

  return (
    <Autocomplete
      popupIcon={<Search />}
      fullWidth
      size="small"
      freeSolo={_freeSolo}
      disabled={disabled}
      classes={{ root: "custom-mui-field custom-auto-complete" }}
      value={defaultValue}
      defaultValue={defaultValue}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      open={isOpen}
      sx={{
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
      filterOptions={(options, params) => {
        const { inputValue } = params;

        if (inputValue.length < dropDownOpenLength) {
          return [];
        }

        return filter(options, params);
      }}
      options={translatedOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={error}
          helperText={error ? helperText : null}
        />
      )}
    />
  );
}
