import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import './ProfilePictureSelector.scss';

interface ProfilePictureSelectionProps {
    emojis?: string[];
    avatars?: string[];
    selectedPicture: string;
    handlePictureClick: (picture: string) => void;
}

const ProfilePictureSelection: React.FC<ProfilePictureSelectionProps> = ({ emojis, avatars, selectedPicture, handlePictureClick }) => {
    const [tabIndex, setTabIndex] = useState(0);

    const localEmojis = [
        '😍', '👻', '💀', '🐙', '🐷', '🦚', '🦊',
        '🐻', '👽', '🐨', '🐶', '🐭', '🐱', '🐯',
        '💩',
    ];

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <Box className='tabs-selector' >
            <Tabs value={tabIndex} onChange={handleTabChange} centered indicatorColor="primary" textColor="primary">
                <Tab label="EMOJIS" />
                <Tab label="AVATARS" />
            </Tabs>

            <Box role="tabpanel" hidden={tabIndex !== 0}>
                {tabIndex === 0 && (
                    <Box className="emoji-container" >
                        {emojis ? emojis : localEmojis.map((emoji, index) => (
                            <Box
                                key={index}
                                onClick={() => handlePictureClick(emoji)}
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: 30,
                                    p: 1,
                                    border: selectedPicture === emoji ? '2px solid purple' : 'none',
                                    borderRadius: 1,
                                    '&:hover': { backgroundColor: '#f0f0f0' }
                                }}
                            >
                                {emoji}
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>

            <Box role="tabpanel" hidden={tabIndex !== 1}>
                {tabIndex === 1 && (
                    <Box className="avatar-container" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, mt: 2 }}>
                        {avatars!.length > 0 ? (
                            avatars?.map((avatar, index) => (
                                <img
                                    key={index}
                                    src={avatar}
                                    alt="avatar"
                                    onClick={() => handlePictureClick(avatar)}
                                    style={{
                                        width: 50,
                                        height: 50,
                                        cursor: 'pointer',
                                        border: selectedPicture === avatar ? '2px solid purple' : 'none',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                />
                            ))
                        ) : (
                            <p>No avatars available</p>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ProfilePictureSelection;
