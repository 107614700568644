import React, { ReactElement } from 'react'
import "./IconButton.scss"

type IconButtonProps = {
    icon?: ReactElement;
    label: string;
    onClick: () => void;
}

const IconButton = (props: IconButtonProps) => {
    return (
        <button onClick={props.onClick}>
            <div className='button-container'>
                {props.icon}
                <span>{props.label}</span>
            </div>

        </button>
    )
}

export default IconButton