import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore"
import { auth, firestore, storage } from "../services/firebaseService"
import { getFromLocalStorage } from "./storageConfig";
import { signInWithCustomToken } from "firebase/auth";
import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytes, uploadString } from "firebase/storage";
import { ICustomFile, ItineraryDocument } from "./Interfaces";
import { compressData, decompressData, removeDotsFromEmail, replaceUndefinedWithNull } from "./helperfunctions";
import { showAlert } from "../store/slices/alertSlice";

class FirebaseFunctions {

    authenticateUser = async (token: string, dispatch?: any) => {
        try {
            await signInWithCustomToken(auth, token)
            return;
        } catch (error: any) {
            if (dispatch)
                dispatch(showAlert({ message: 'Login failed', severity: 'error' }))
            throw new Error(error);
        }
    }

    updateItineraryDocument = async (templateId: string, itineraryData: ItineraryDocument) => {
        try {
            const tenantId = getFromLocalStorage('tenantId');
            const itineraryRef = doc(firestore, `tenants/${tenantId}/${this.getItineraryCollection(itineraryData.documentType)}/`, templateId);

            const modifiedDateData = { ...itineraryData, modifiedAt: new Date().toISOString() } as ItineraryDocument;
            delete modifiedDateData.documentId;
            const cleanedData = replaceUndefinedWithNull(modifiedDateData.documentContent);

            const compressedDocContent = compressData(cleanedData);

            const updatedData: ItineraryDocument = {
                ...modifiedDateData,
                documentContent: compressedDocContent,
                // compressed: true,
            };


            await setDoc(itineraryRef, updatedData);
        } catch (error) {
            console.error('Error updating template:', error);
        }
    };

    addItineraryDocument = async (itineraryData: ItineraryDocument) => {
        try {
            const tenantId = getFromLocalStorage('tenantId');
            const itineraryRef = collection(firestore, `tenants/${tenantId}/${this.getItineraryCollection(itineraryData.documentType)}`);

            const modifiedDateData = { ...itineraryData, createdAt: new Date().toISOString() } as ItineraryDocument;

            const cleanedData = replaceUndefinedWithNull(modifiedDateData.documentContent);

            const compressedDocContent = compressData(cleanedData);

            const updatedData: ItineraryDocument = {
                ...modifiedDateData,
                documentContent: compressedDocContent,
                // compressed: true,
            };

           const document =  await addDoc(itineraryRef, updatedData);

           return document.id;
        } catch (error) {
            console.error('Error updating template:', error);
        }
    };

    getItineraryDocument = async (documentId: string, documentType: 'itinerary' | 'template' | 'page'): Promise<ItineraryDocument | undefined> => {
        try {

            const tenantId = getFromLocalStorage('tenantId');
            if (!tenantId) throw new Error("Tenant ID is missing");
            console.log(`tenants/${tenantId}/${this.getItineraryCollection(documentType)}/`, documentId);

            const itineraryRef = doc(firestore, `tenants/${tenantId}/${this.getItineraryCollection(documentType)}/`, documentId);

            const templateSnap = await getDoc(itineraryRef);

            if (!templateSnap.exists()) {
                throw new Error("Template not found");
            }

            let template = templateSnap.data() as ItineraryDocument;

            // if (template.compressed) {
            const decompressed = decompressData(template.documentContent);
            template = { ...template, documentContent: decompressed, documentId };
            // }

            return template;
        } catch (error) {
            console.log("Error fetching template:", error);
            return undefined; // Return undefined when there's an error
        }
    };

    addMediaFirebase = async (
        fileName: string,
        file: File
    ): Promise<ICustomFile | null> => {
        try {
            const tenantId = getFromLocalStorage("tenantId");
            let fileId = `${fileName}${Date.now()}`;
            const storageRef = ref(storage, `${tenantId}/medias/${fileId}`);
            let downloadLink = "";
            const snapshot = await uploadBytes(storageRef, file);
            downloadLink = await getDownloadURL(snapshot.ref);
            const metaData = await getMetadata(snapshot.ref);
            const fileDetails: ICustomFile = {
                id: fileId,
                name: fileName,
                type: metaData.contentType || "unknown",
                size: metaData.size || 0,
                lastModified: metaData.updated,
                downloadLink: downloadLink,
            };
            return fileDetails;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    deleteMediaFirebase = async (fileId: string): Promise<boolean> => {
        try {
            const tenantId = getFromLocalStorage("tenantId");
            const storageRef = ref(storage, `${tenantId}/medias/${fileId}`);
            await deleteObject(storageRef);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    uploadBase64Image = async (base64Image: string, email: string) => {
        try {
            let modifiedEmail = removeDotsFromEmail(email);

            const storageRef = ref(storage, `profilePictures/${modifiedEmail}`);

            const cleanedBase64String = base64Image
                .replace(/^data:image\/[a-z]+;base64,/, '')  // Remove the data URL prefix
                .replace(/-/g, '+')                          // Replace '-' with '+'
                .replace(/_/g, '/');                         // Replace '_' with '/'

            const snapshot = await uploadString(storageRef, cleanedBase64String, "base64");

            const downloadURL = await getDownloadURL(snapshot.ref);

            console.log("File available at", downloadURL);
            return downloadURL;
        } catch (error) {
            console.error("Error uploading Base64 image:", error);
            throw error;
        }
    };

    uploadBlob = async (blob: Blob, email: string) => {
        try {
            let modifiedEmail = removeDotsFromEmail(email);
            const storageRef = ref(storage, `profilePictures/${modifiedEmail}`);

            const snapshot = await uploadBytes(storageRef, blob);

            const downloadURL = await getDownloadURL(snapshot.ref);

            console.log("File available at", downloadURL);
            return downloadURL;
        } catch (error) {
            console.error("Error uploading Blob:", error);
        }
    };

    compressImage = (file: string, quality: number = 0.5) => {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = file;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800;
                const scaleSize = maxWidth / img.width;

                canvas.width = maxWidth;
                canvas.height = img.height * scaleSize;

                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

                const compressedData = canvas.toDataURL('image/jpeg', quality);
                resolve(compressedData);
            };
            img.onerror = (error) => reject(error);
        });
    };

    savePreviewFirebase = async (blob: Blob, itineraryId: string) => {
        try {
            const tenantId = getFromLocalStorage('tenantId');

            const pdfRef = ref(storage, `${tenantId}/preview/preview_${itineraryId}.png`);

            let downloadLink = "";
            await uploadBytes(pdfRef, blob).then(async (snapshot) => {
                await getDownloadURL(snapshot.ref).then((link: string) => {
                    downloadLink = link;
                })
            });

            return downloadLink;

        } catch (error) {
            console.error("Error uploading PDF to Firebase Storage:", error);
        }
    };

    uploadBase64AsPDF = async (dataUrl: string, itineraryId: string) => {
        try {
            const tenantId = getFromLocalStorage('tenantId');

            const pdfRef = ref(storage, `${tenantId}/pdfs/pdf_${itineraryId}.pdf`);

            let downloadLink = "";

            await uploadString(pdfRef, dataUrl.split(',')[1], "base64", { contentType: 'application/pdf' }).then(async (snapshot) => {
                await getDownloadURL(snapshot.ref).then((link: string) => {
                    downloadLink = link;
                })
            })

            return downloadLink;
        } catch (error) {
            console.log(error);
        }
    }

    getItineraryCollection = (type: 'itinerary' | 'template' | 'page'): ('itineraries' | 'templates' | 'pages') => {
        switch (type) {
            case 'itinerary':
                return 'itineraries';
            case 'template':
                return 'templates';
            case 'page':
                return 'pages';
        }
    }
}

export default FirebaseFunctions;