import { FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import './IconCustomSelect.scss'
import '../MuiBaseStyle.scss'
import { t } from "i18next";
import { DropdownItemProps } from "../CustomAutocomplete/CustomAutocomplete";

export function IconCustomSelect(props: {
    error?: boolean, require?: boolean, helperText?: string, label?: string, options: DropdownItemProps[],
    onSelect: (value: DropdownItemProps) => void, defaultValue?: DropdownItemProps, disabled?: boolean, size?: FormControlProps['size']
}) {

    const getOptions = () => {
        const list = props.options.map(item => ({ ...item, label: t(item.label) }));
        return list;
    }

    return (
        <FormControl disabled={props.disabled ?? false} required={props.require} error={props.error} classes={{ root: 'custom-mui-field custom-select-field' }} fullWidth size={props.size ?? "small"}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.defaultValue?.id}
                renderValue={() => (
                    <>
                        {props.defaultValue?.icon}
                        <span>{props.defaultValue?.label}</span>
                    </>
                )}
                label={props.label}
                onChange={(event: any, value: any) => props.onSelect({ label: value.props.children, id: value.props.value } as DropdownItemProps)}
            >
                {getOptions().map(option => (<MenuItem key={option.label} value={option.id.toString()}>
                    {option.icon ? <div>
                        <div className="dropdown-container"><i>{option.icon}</i> <span>{option.label}</span></div>
                    </div> : option.label}</MenuItem>))}
            </Select>
            <FormHelperText>{props.error ? (props.helperText ? props.helperText : "Required") : ""}</FormHelperText>
        </FormControl>
    );
}