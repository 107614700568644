import React, { ReactElement, useRef } from 'react';
interface Props {
    onSelect: (files: FileList | null) => void;
    icon?: ReactElement;
    loading?: boolean
}
const FileUpload: React.FC<Props> = ({ onSelect: handleFileUpload, icon, loading }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleFileUploadClick = () => {
        if (!loading) {
            fileInputRef.current?.click();
        }
    };
    return (
        <>
            <div onClick={handleFileUploadClick} style={{ display: 'inline-block', cursor: 'pointer' }}>
                {icon}
            </div>
            <input
                type="file"
                ref={fileInputRef}
                multiple
                style={{ display: 'none' }}
                onChange={(e) => handleFileUpload(e.target.files)}
            />
        </>
    );
};
export default FileUpload;