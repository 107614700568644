import { useEffect, useRef, useState } from "react";
import CustomDrawer from "../Mui/CustomDrawer/CustomDrawer";
import TransparentButton from "../../components/TransparentButton/TransparentButton";
import BoardSvg from "../../assets/images/buttons/BoardSvg";
import "./ViewLead.scss";

import goBack from "../../assets/images/go-back.svg";
import EditLeadUserData from "./EditLeadUserData";
import CurrentItinerary from "./CurrentItinerary";
import LeadLogs from "./LeadLogs";
import { IAssignee, ICreateLeadUser, ILead, ILog, ItineraryDocument } from "../../helpers/Interfaces";
import CustomStageDropDown from "../Mui/CustomStageDropDown/CustomStageDropDown";
import ViewLeadFunctions from './ViewLeadFunctions';
import dayjs from "dayjs";
import { DropdownItemProps } from "../Mui/CustomAutocomplete/CustomAutocomplete";
import AddUserSvg from "../../assets/images/ViewLead/AddUserSvg";
import SearchableDropdown from "../SerchableDropdown/SearchableDropdown";
import { Avatar, AvatarGroup } from "@mui/material";
import { stringAvatar } from "../../helpers/helperfunctions";
import { t } from "i18next";

type ViewLeadProps = {
  readonly open: boolean;
  readonly close: () => void;
  readonly lead: ILead;
  readonly refresh: () => void
  readonly editLead?: () => void
  readonly users: DropdownItemProps[],
  readonly editUser?: (user: ICreateLeadUser) => void
};

function ViewLead({ open, close, lead, refresh, editLead, users, editUser }: ViewLeadProps) {
  const [localLead, setLocalLead] = useState<ILead>({
    customer: { name: '', phoneNumber: '', email: '', nationality: '' }, stage: 'NEW_LEAD',
    startDate: dayjs(new Date()), endDate: dayjs(new Date())
  });
  const [logs, setLogs] = useState<ILog[]>([]);
  const [height, setHeight] = useState(0);
  const divARef = useRef<HTMLDivElement | null>(null);
  const divBRef = useRef<HTMLDivElement | null>(null);
  const functions = new ViewLeadFunctions();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [itineraries, setItineraries] = useState<{ sent: ItineraryDocument[], draft: ItineraryDocument[] }>({ sent: [], draft: [] });
  const [itineraryLoading, setItineraryLoading] = useState<boolean>(false);

  useEffect(() => {
    if (lead.id) {
      setLocalLead(lead);
      functions.getLogs(lead.id ?? '', setLogs);

      functions.getLeadItineraries(lead.id, setItineraries, setItineraryLoading);
    }
  }, [lead])

  const updateHeight = () => {
    if (divARef.current) {
      setHeight(divARef.current.clientHeight);
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (divBRef.current) {
      divBRef.current.style.height = `${height + 5}px`;
    }
  }, [height]);

  const handleClose = () => {
    setLogs([]);
    close();
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  const openDropdown = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const assignees: IAssignee[] = localLead.assignees
    ? localLead.assignees.map(t => ({ id: t.id, name: t.name }))
    : [];

  const defaultAssignees: DropdownItemProps[] = localLead.assignees
    ? localLead.assignees.map(t => ({ id: t.id, label: t.name }))
    : [];



  const defaultDisabledAssignees = (): DropdownItemProps[] => {
    if (!lead.assignees) return [];

    const userIdsSet = new Set(users.map(u => u.id));

    return lead.assignees
      .filter(assignee => !userIdsSet.has(assignee.id))
      .map(({ id, name }) => ({ id, label: name }))
  }

  return (
    <CustomDrawer open={open} close={close}>
      <div className="exp-modal-inner">
        <div className="top-middle">
          <div className="top">
            <div className="gb-head">
              {/* <img aria-hidden src={goBack} className="go-back-arrow" alt="go back" onClick={handleClose} /> <span className="fz-32 primary-font">{localLead.user.name}</span> */}
              <img aria-hidden src={goBack} alt="go back" onClick={handleClose} /> <span className="fz-32 primary-font">{localLead.customer.name}</span>
            </div>
            <EditLeadUserData lead={localLead} onDelete={() => {
              functions.deleteLead(lead.id ?? '', () => {
                refresh();
                close();
              })
            }} showEdit={editUser !== undefined} onEditUser={() => {
              if (!editUser) return;
              editUser(lead.customer);
              close();
            }} />
          </div>
          <div className="middle card-view-inner-top">
            <div className="card-details">
              <div className="top">
                <div className="left">
                  <div className="title">
                    <h3 className="fz-24 primary-font fw-400 mb-0">{t('currentItinerary')}</h3>
                  </div>
                </div>
                <div className="right">
                  <div className="label">
                    <CustomStageDropDown lead={lead} changeStage={(stage: string) => {
                      functions.updateLeadStage(lead.id ?? '', stage, () => {
                        refresh();
                        functions.getLogs(lead.id ?? '', setLogs);
                      });
                    }} />
                  </div>
                  <span className="assign-user" onClick={handleClick}>
                    {assignees.length > 0
                      ? (<AvatarGroup max={1}>
                        {assignees.map(avatar => (<Avatar className="avatar-icon" {...stringAvatar({ name: avatar.name, fontSize: '10px', size: 23, id: avatar.id })} />))}
                      </AvatarGroup>)
                      : (<AddUserSvg size={26} />)}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-status">
              <div className="top">
                <div className="left">
                  <div className="view-styles bdr-10">
                    <TransparentButton active icon={<BoardSvg />} label={t('activity')} onClick={() => { }} />
                  </div>
                </div>
                <div className="right"></div>
              </div>
            </div>
          </div>
          <div className="middle card-view-inner-bottom">
            <CurrentItinerary itineraries={itineraries} itineraryLoading={itineraryLoading} manageAssignee={(assignee, id) => functions.addTaskAssignee(localLead, setLocalLead, id, assignee, () => { functions.getLogs(lead.id ?? '', setLogs); refresh() })} users={users} divARef={divARef} lead={localLead} setLeads={setLocalLead} onEditLead={editLead}
              refresh={() => { refresh(); functions.getLogs(lead.id ?? '', setLogs); }} />
            <LeadLogs divBRef={divBRef} logs={logs} />
          </div>
        </div>
        <SearchableDropdown disableItems={defaultDisabledAssignees()} selectedItems={defaultAssignees} onSelect={(val) => {
          handleCloseDropDown();
          functions.addLeadAssignee(localLead, setLocalLead, val, () => {
            functions.getLogs(lead.id ?? '', setLogs);
            refresh();
          });
        }} initialDetails={users} id={id} open={openDropdown} anchorEl={anchorEl} handleClose={handleCloseDropDown} />
      </div>
    </CustomDrawer>
  );
}

export default ViewLead;
