import { ChangeEvent, useEffect, useState } from 'react'
import ApiService from '../../../../../services/ApiService'
import { ItineraryDocument } from '../../../../../helpers/Interfaces';
import BuilderFunctions from '../../BuilderFunctions';
import { StoreType } from 'polotno/model/store';
import { Box, Skeleton } from '@mui/material';
import { ImagesGrid } from 'polotno/side-panel';
import { DropdownItemProps } from '../../../../../components/Mui/CustomAutocomplete/CustomAutocomplete';
import CustomTextField from '../../../../../components/Mui/CustomTextField/CustomTextField';

const BuilderPages = ({ store, pages, loading }: { store: StoreType, pages: ItineraryDocument[], loading?: boolean }) => {
    const [filteredTemplates, setFilteredTemplates] = useState<ItineraryDocument[]>([]);
    const apiService = new ApiService();
    const builderFunction = new BuilderFunctions();

    useEffect(() => {
        if (pages) {
            setFilteredTemplates(pages);
        }
    }, [pages])

    const onChangeInput = (val: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (val.target.value === "") {
            return setFilteredTemplates(pages);
        }

        const filteredTem = pages.filter((pages) =>
            pages.documentName?.toLocaleLowerCase().includes(val.target.value) ||
            pages.documentCategory?.toLocaleLowerCase().includes(val.target.value));

        return setFilteredTemplates(filteredTem);
    }

    return (
        <div className='template-outer'>
            <div className="header-outer">
                <CustomTextField size='small' label={"Search by name"} onChange={onChangeInput} />
            </div>
            <ImagesGrid
                images={filteredTemplates}
                getPreview={(template) => {
                    return template.previewUrl ?? ""
                }}
                onSelect={(template, pos, element, event) => builderFunction.addDocument({ store, itinerary: template })}
                rowsNumber={2}
                isLoading={loading ?? false}
                loadMore={false}
                getCredit={(template) => (<span>  {`${template.documentName}, ${template.documentCategory}`} </span>)}
            />
        </div>
    )
}

export default BuilderPages