import { ChangeEvent, useEffect, useState } from 'react'
import { ItineraryDocument } from '../../../../../helpers/Interfaces';
import './BuilderTemplate.scss'
import { StoreType } from 'polotno/model/store';
import BuilderFunctions from '../../BuilderFunctions';
import { ImagesGrid } from 'polotno/side-panel';
import CustomTextField from '../../../../../components/Mui/CustomTextField/CustomTextField';

const BuilderTemplates = ({ store, templates, loading }: { store: StoreType, templates: ItineraryDocument[], loading?: boolean }) => {
    const [filteredTemplates, setFilteredTemplates] = useState<ItineraryDocument[]>([]);
    const builderFunction = new BuilderFunctions();

    useEffect(() => {
        if (templates) {
            setFilteredTemplates(templates);
        }
    }, [templates])

    const onChangeInput = (val: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (val.target.value === "") {
            return setFilteredTemplates(templates);
        }

        const filteredTem = templates.filter((template) =>
            template.documentName?.toLocaleLowerCase().includes(val.target.value.toLocaleLowerCase()) ||
            template.documentCategory?.toLocaleLowerCase().includes(val.target.value.toLocaleLowerCase()));

        return setFilteredTemplates(filteredTem);
    }

    return (
        <div className='template-outer'>
            <div className="header-outer">
                <CustomTextField size='small' label={"Search by name"} onChange={onChangeInput} />
            </div>
            <ImagesGrid
                images={filteredTemplates}
                getPreview={(template) => {
                    return template.previewUrl ?? ""
                }}
                onSelect={(template, pos, element, event) => builderFunction.addDocument({ store, itinerary: template })}
                rowsNumber={2}
                isLoading={loading ?? false}
                loadMore={false}
                getCredit={(template) => (<span>  {`${template.documentName}, ${template.documentCategory}`} </span>)}
            />
        </div>
    )
}

export default BuilderTemplates