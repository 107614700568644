
import globeIcon from "../../assets/images/lead-card/globe.svg";
import phoneIcon from "../../assets/images/lead-card/phone.svg";
import emailIcon from "../../assets/images/lead-card/email.svg";
import { ILead } from "../../helpers/Interfaces";
import { DeleteOutline } from "@mui/icons-material";

const EditLeadUserData = ({ lead, onEditUser, onDelete, showEdit }: { lead: ILead, showEdit: boolean, onEditUser: CallableFunction, onDelete: CallableFunction }) => {
    return (
        <div className="rht">
            <ul className="normal-list horizontal-icon-list">
                <li className="lead-icon-item">
                    <span className="icon">
                        <img src={phoneIcon} alt="Phone" />
                    </span>
                    <span className="txt" style={{ whiteSpace: "nowrap" }}>
                        {lead.customer.phoneNumber}
                    </span>
                </li>
                <li className="lead-icon-item">
                    <span className="icon">
                        <img src={emailIcon} alt="Email" />
                    </span>
                    <span className="txt">{lead.customer.email}</span>
                </li>
                <li className="lead-icon-item">
                    <span className="icon">
                        <img src={globeIcon} alt="Phone" />
                    </span>
                    <span className="txt">{lead.customer.nationality}</span>
                </li>
                <li className="lead-icon-item">
                    {showEdit && (<button onClick={() => onEditUser()} className="icon-btn" style={{ marginRight: '2px' }}>
                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5332 4.5166L1.95817 13.0916C1.6422 13.4082 1.4425 13.8222 1.39151 14.2666L1.04984 17.2666C1.02757 17.4776 1.05005 17.6909 1.11581 17.8925C1.18157 18.0942 1.28914 18.2798 1.43149 18.4371C1.57383 18.5944 1.74776 18.7198 1.9419 18.8054C2.13604 18.8909 2.34604 18.9344 2.55817 18.9333H2.73317L5.73317 18.5916C6.17576 18.5404 6.58894 18.3441 6.90817 18.0333L15.4832 9.44993L10.5332 4.5166Z" fill="#E27C37" />
                            <path d="M18.3832 3.79138L16.2082 1.61638C16.0262 1.4337 15.81 1.28876 15.5719 1.18985C15.3338 1.09095 15.0785 1.04004 14.8207 1.04004C14.5628 1.04004 14.3076 1.09095 14.0695 1.18985C13.8313 1.28876 13.6151 1.4337 13.4332 1.61638L11.4165 3.63304L16.3665 8.58304L18.3832 6.56638C18.5658 6.38443 18.7108 6.1682 18.8097 5.9301C18.9086 5.69199 18.9595 5.4367 18.9595 5.17888C18.9595 4.92105 18.9086 4.66576 18.8097 4.42766C18.7108 4.18955 18.5658 3.97333 18.3832 3.79138Z" fill="#E27C37" />
                        </svg>
                    </button>)}
                    <button onClick={() => onDelete()} className="icon-btn">
                        <DeleteOutline />
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default EditLeadUserData