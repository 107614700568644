import { t } from "i18next"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom'

type MainMenuProps = {
    title: string,
    icon: ReactElement,
    childList?: { name: string, route: string }[],
    defaultTab?: number,
    notificationCount?: number
}

const MainMenu = ({ title, icon, childList, defaultTab, notificationCount }: MainMenuProps) => {
    const location = useLocation();
    const routes = childList ? childList?.map(child => { return { ...child, route: `/dashboard/${child.route}` } }) : [{ name: title, route: `/dashboard/${title.replaceAll(' ', '-')}` }];

    return (
        <li className="main-manu-item">
            <Link to={routes[defaultTab ?? 0].route} className={`main-manu-link ${routes.some(item => (item.route === location.pathname)) && 'active'}`}>
                <span className="icon">
                    {icon}
                </span>
                <span className="txt">{t(title)}</span>
                {notificationCount ? <span className="msg-count">{notificationCount}</span> : <></>}
            </Link>
            {childList
                && (routes.some(item => (item.route === location.pathname))
                    && (<ul className="child-list normal-list">
                        {childList?.map((child, index) => (
                            <li key={child.route} className="child-list-item">
                                <Link to={routes[index].route} className={`child-list-link ${location.pathname === routes[index].route && 'active'}`}>
                                    <span className="txt-item">{t(child.name)}</span></Link>
                            </li>))}
                    </ul>))}
        </li>
    )
}

export default MainMenu