import { CSSProperties, MouseEventHandler, ReactElement } from 'react';
import './ButtonWithoutBorder.scss';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

type BorderlessButtonProps = {
    onClick?: MouseEventHandler<HTMLButtonElement>,
    label: string,
    icon?: ReactElement,
    color?: CSSProperties['color'],
    loading?: boolean
}

const BorderlessButton = ({ onClick, label, icon, color, loading }: BorderlessButtonProps) => {
    return (
        <button style={{ color }} onClick={onClick} className="drop-button" type="button">
            {loading ? <CircularProgress size={16} color='inherit' /> : (icon
                ? (<span className="icon">
                    {icon}
                </span>)
                : (<></>))}
            <span className="txt fz-16 ttu primary-font" style={{ color }}>{label}</span>
        </button>
    )
}

export default BorderlessButton