import ApiService from "../../../services/ApiService";

class CalenderFunctions {
    apiService = new ApiService()

    getAllTasks(setTasks: CallableFunction, setLoading: CallableFunction) {
        setLoading(true);
        this.apiService.getCalenderAllTasks()
            .then((response) => {
                setTasks(response.data.data)
            }).catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            })
    }

    getLeadById(id: string, setTasks: CallableFunction, setLoading: CallableFunction) {
        setLoading(true);
        this.apiService.getLeadById({ leadId: id })
            .then((response) => {
                const lead = response.data.lead;
                lead.id = id;
                setTasks(lead)
            }).catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            })
    }
}

export default CalenderFunctions;