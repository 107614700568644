import { Navigate, RouteObject, BrowserRouter as Router, useNavigate, useRoutes } from "react-router-dom";
import LeadTracker from "./pages/Dashboard/LeadTracker/LeadTracker";
import logoImg from "./assets/images/logo.svg";
import "./App.scss";
import "./Master.scss";
import NotImplemented from "./pages/NotImplemented/NotImplemented";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from "./store/store";
import Calender from "./pages/Dashboard/Calender/Calender";
import Builder from "./pages/Dashboard/Builder/Builder";
import { useTranslation } from "react-i18next";
import { t, use } from "i18next";
import { Libraries, LoadScript } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
import { googleApiKey } from "./helpers/helperfunctions";
import FirebaseFunctions from "./helpers/firebaseFunctions";
import { getFromLocalStorage } from "./helpers/storageConfig";
import { auth } from "./services/firebaseService";
import Library from "./pages/Dashboard/Library/Library";
import createStore from "polotno/model/store";
import Profile from "./pages/Profile/Profile";
import SetNewPassword from "./pages/SetNewPassword/SetNewPassword";
import PositionedSnackbar from "./components/Mui/PositionedSnackBar/PositionedSnackBar";
import MaintenancePage from "./pages/Maintenance/MaintenancePage";

const AppRoutes = ({ loggedIn, loading }: { loggedIn: boolean, loading: boolean }) => {
  return useRoutes(getRoute(loggedIn, loading));
}

const getRoute = (loggedIn: boolean, loading: boolean): RouteObject[] => {
  // return [{ path: "/*", element: (<MaintenancePage />) }]
  if (loading) {
    return [{ path: "/", element: (<Loading />) }];
  } else {
    return [
      { path: "/", element: loggedIn ? (<Navigate to={"/dashboard/lead-tracker"} />) : (<Login />) },
      { path: "/login", element: loggedIn ? (<Navigate to={"/dashboard/lead-tracker"} />) : (<Login />) },
      { path: '/forgot-password', element: (<ForgotPassword />) },
      { path: '/set-password', element: (<SetNewPassword />) },
      { path: "/dashboard/lead-tracker", element: loggedIn ? (<LeadTracker />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/calendar", element: loggedIn ? (<Calender />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/builder", element: loggedIn ? (<Builder />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/my-profile", element: loggedIn ? (<Profile />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/library-templates", element: loggedIn ? (<Library />) : (<Navigate to={"/"} />) },
      { path: "*", element: loggedIn ? (<NotImplemented />) : <Navigate to={"/"} /> },
    ]
  }
}

function App() {
  const { i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const libraries: Libraries = ['places'];

  useEffect(() => {
    validateLogin();
  }, [user])

  const validateLogin = async () => {
    const customToken = getFromLocalStorage('firebaseCustomToken');
    i18n.changeLanguage(user.language);
    if (user.displayName) {
      return setLoggedIn(true);
    }
    return setLoggedIn(false);
  }

  return (
    <>
      <section className="small-screen-message">
        <div className="txt">
          <img src={logoImg} className="logo" alt="logo" />
          <h4>{t('screenWidthHeader')}</h4>
          <p>{t('screenWidthDescription')}</p>
        </div>
      </section>
      <main className="main">
        <LoadScript googleMapsApiKey={googleApiKey()} libraries={libraries} loadingElement={<Loading />}>
          <Router>
            <AppRoutes loading={loading} loggedIn={loggedIn} />
          </Router>
        </LoadScript>
        <PositionedSnackbar />
      </main>
    </>
  );
}

export default App;

const Loading = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress color='inherit' size={'20px'} />
    </div>
  )
}