import { Modal } from "@mui/material";
import "./TemplatePreview.scss";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";
import SendIcon from "../../../assets/images/builder/SendIcon";
import { formatDate } from "../../../helpers/helperfunctions";
import { MouseEventHandler, useContext, useState } from "react";
import { ItineraryDocument } from "../../../helpers/Interfaces";
import CalenderOrangeSvg from "../../../assets/images/Library/CalenderOrangeSvg";
import CategorySvg from "../../../assets/images/Library/CategorySvg";
import { CloseRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setItinerary } from "../../../store/slices/itinerarySlice";
import { AppDispatch } from "../../../store/store";
import { Store } from "../../../store/polontoStore";
import { LibraryFunctions } from "../../../pages/Dashboard/Library/LibraryFunctions";

type TemplatePreviewProps = {
  open: boolean,
  close: MouseEventHandler<HTMLButtonElement>,
  itinerary?: ItineraryDocument,
  clone?: CallableFunction,
  delete: CallableFunction
}

const TemplatePreview = (props: TemplatePreviewProps) => {
  const [loading, setLoading] = useState<{ edit: boolean, clone: boolean, delete: boolean }>({ edit: false, clone: false, delete: false });
  const libraryFunction = new LibraryFunctions()
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [_, loadJson, loadPage] = useContext(Store);

  const editTemplate = async () => {
    setLoading({ ...loading, edit: true });
    const template = await libraryFunction.editTemplate(props.itinerary);

    if (!template) return
    dispatch(setItinerary(template));
    if (props.itinerary?.documentType === 'page') {
      loadPage(template.documentContent);
    } else {
      loadJson(template.documentContent);
    }
    setLoading({ ...loading, edit: false });
    navigate('/dashboard/builder');
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="template-preview-container">
        <button className="close-icon" onClick={props.close}>
          <CloseRounded />
        </button>
        {props.itinerary?.documentType === 'template'
          ? (props.itinerary?.pdfUrl
            ? (<embed className='pdf-viewer' src={`${props.itinerary?.pdfUrl}#toolbar=0`} type="application/pdf" />)
            : (<div className="pdf-viewer">
              Preview not available
            </div>))
          : props.itinerary?.previewUrl
            ? (<img className='pdf-viewer' src={props.itinerary?.previewUrl} />)
            : (<div className="pdf-viewer">
              Preview not available
            </div>)}
        <div className="template-content">
          <div>
            <div className="template-name">{props.itinerary?.documentName}</div>
            <div className="data-inner c-black-gray">
              <CalenderOrangeSvg size={15} />
              {formatDate(props.itinerary?.createdAt, 'DD/MM/YYYY')}</div>
            <div className="data-inner c-black-gray">
              <CategorySvg size={15} />
              {props.itinerary?.documentCategory}</div>
            {/* <div className="data-inner c-black-gray">
              <TagSvg size={15} />
              about us, general pages, english</div> */}
          </div>

          <div className="button-outer">
            <SecondaryButton loading={loading.edit} style={{ width: '100%', justifyContent: 'center', fontWeight: 'initial' }}
              onClick={editTemplate} label={'edit'} />
            {/* <SecondaryButton loading={loading.clone} borderButton style={{ width: '100%', justifyContent: 'center', fontWeight: 'initial' }}
              onClick={()=> props.clone(props.itinerary)} label={'clone'} /> */}
            <button onClick={() => props.delete()} className="delete-template">delete</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplatePreview;
