import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import eyeImg from '../../assets/images/eye.svg';
import eyeClose from '../../assets/images/eyeClose.svg';
import userImg from "../../assets/images/user.svg";
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import './ChangePassword.scss'

interface ChangePasswordFormProps {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    loading: boolean;
    onChangeCurrentPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeNewPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeConfirmPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ newPassword, confirmPassword, loading, onChangeNewPassword, onChangeConfirmPassword, onSubmit, currentPassword, onChangeCurrentPassword }) => {

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    }

    return (
        <form onSubmit={onSubmit} className='password-reset-form'>
            <div className='form-group'>
                <input
                    required
                    type={showCurrentPassword ? 'text' : 'password'}
                    className='form-control'
                    value={currentPassword}
                    placeholder='Enter Current password'
                    onChange={onChangeCurrentPassword}
                />
                <button onClick={toggleCurrentPasswordVisibility} className='password-toggle' type='button'> <img src={showCurrentPassword ? eyeClose : eyeImg} alt='' /> </button>
            </div>
            <div className='form-group'>
                <input
                    required
                    type={showNewPassword ? 'text' : 'password'}
                    className='form-control'
                    value={newPassword}
                    placeholder='Enter new password'
                    onChange={onChangeNewPassword}
                />
                <button onClick={toggleNewPasswordVisibility} className='password-toggle' type='button'> <img src={showNewPassword ? eyeClose : eyeImg} alt='' /> </button>
            </div>
            <div className='form-group'>
                <input
                    required
                    type={showConfirmPassword ? 'text' : 'password'}
                    className='form-control'
                    placeholder='Confirm new password'
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                />
                <button onClick={toggleConfirmPasswordVisibility} className='password-toggle' type='button'> <img src={showConfirmPassword ? eyeClose : eyeImg} alt='' /> </button>
            </div>
            <button type='submit' className='submit fz-16 primary-font fw-600 ttu c-white'>
                {loading ? <CircularProgress color='inherit' size={28} /> : t('changePassowrd')}
            </button>
            {/* <div className="sm-msg primary-font" style={{ width: "100%" }}>
                <span className="inner">  <img src={userImg} alt="user" /> <span className="txt"> Remember your password? <span className="c-orange"> <Link to="/login" style={{ color: 'inherit', textDecoration: 'none' }} > Log in </Link>  </span> </span> </span>
            </div> */}
        </form>
    );
};

export default ChangePasswordForm;
