import React from 'react'
const PdfIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7994 8.55476V22.7878C22.7994 24.1131 21.7251 25.1875 20.3997 25.1875H5.60089C4.27555 25.1875 3.20117 24.1131 3.20117 22.7878V3.21222C3.20112 1.88688 4.2755 0.8125 5.60089 0.8125H15.5128L22.7994 8.55476Z" fill="#E5252A" />
            <path d="M22.8003 8.55476H17.1135C16.2299 8.55476 15.5137 7.83849 15.5137 6.95495V0.8125L22.8003 8.55476Z" fill="#ED676A" />
            <path d="M8.31548 17.9293V19.551H7.16406V14.7734H9.07009C9.43566 14.7734 9.75909 14.8406 10.0403 14.975C10.3215 15.1094 10.5387 15.3015 10.6934 15.5499C10.8465 15.7984 10.9246 16.0796 10.9246 16.3951C10.9246 16.8607 10.7574 17.2326 10.4231 17.5122C10.0903 17.7903 9.63249 17.9293 9.04977 17.9293H8.31548ZM8.31548 17.0404H9.07009C9.29352 17.0404 9.46379 16.9857 9.58094 16.8732C9.6981 16.7623 9.75594 16.6045 9.75594 16.4014C9.75594 16.1779 9.695 15.9999 9.5747 15.8655C9.4544 15.7327 9.29037 15.6655 9.08258 15.6624H8.31548V17.0404Z" fill="white" />
            <path d="M11.5371 19.551V14.7734H13.076C13.4978 14.7734 13.8775 14.8688 14.2149 15.0609C14.5508 15.2515 14.8148 15.5218 15.0039 15.8718C15.193 16.2202 15.2882 16.6108 15.2898 17.0435V17.2638C15.2898 17.7012 15.1976 18.0934 15.0132 18.4403C14.8289 18.7871 14.568 19.0574 14.2321 19.2542C13.8962 19.4495 13.5212 19.5479 13.1087 19.5511H11.5371V19.551ZM12.6886 15.6624V18.6652H13.0885C13.4197 18.6652 13.6728 18.5481 13.8494 18.3121C14.0275 18.0778 14.1165 17.7278 14.1165 17.2638V17.0576C14.1165 16.5951 14.0275 16.2483 13.8494 16.0139C13.6728 15.7796 13.4151 15.6624 13.076 15.6624H12.6886Z" fill="white" />
            <path d="M18.8919 17.6481H17.0342V19.551H15.8828V14.7734H19.0778V15.6624H17.0342V16.7623H18.8919V17.6481Z" fill="white" />
        </svg>
    )
}
export default PdfIcon