import React from 'react'

const EditSquare = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1996 5.12758L18.7656 6.37458C18.9168 6.51691 19.0021 6.71571 19.001 6.92339C18.9998 7.13106 18.9124 7.32892 18.7596 7.46958L17.5696 8.89358L13.5206 13.7226C13.4498 13.8037 13.3554 13.8607 13.2506 13.8856L11.1506 14.3616C10.8751 14.3754 10.6352 14.1751 10.5996 13.9016V11.7436C10.6068 11.6395 10.6487 11.5409 10.7186 11.4636L14.6926 6.97058L16.0566 5.34158C16.3265 4.98786 16.8201 4.89545 17.1996 5.12758Z" stroke="#E27C37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.533 7.61816C11.9472 7.61816 12.283 7.28238 12.283 6.86816C12.283 6.45395 11.9472 6.11816 11.533 6.11816V7.61816ZM8.73301 6.86816V6.11816L8.73121 6.11817L8.73301 6.86816ZM5.00001 10.6182H5.75001L5.75001 10.6166L5.00001 10.6182ZM5.00001 16.2432L5.75001 16.2448V16.2432H5.00001ZM8.73301 19.9932L8.73121 20.7432H8.73301V19.9932ZM14.333 19.9932V20.7432L14.3348 20.7432L14.333 19.9932ZM18.066 16.2432H17.316L17.316 16.2448L18.066 16.2432ZM18.816 12.4932C18.816 12.079 18.4802 11.7432 18.066 11.7432C17.6518 11.7432 17.316 12.079 17.316 12.4932H18.816ZM15.3863 6.68398C15.2282 6.3011 14.7897 6.11886 14.4068 6.27691C14.0239 6.43497 13.8417 6.87348 13.9998 7.25635L15.3863 6.68398ZM17.7319 9.62148C18.1363 9.53208 18.3917 9.13173 18.3023 8.72728C18.2129 8.32283 17.8126 8.06744 17.4081 8.15684L17.7319 9.62148ZM7.80001 16.4312C7.3858 16.4312 7.05001 16.767 7.05001 17.1812C7.05001 17.5954 7.3858 17.9312 7.80001 17.9312V16.4312ZM15.267 17.9312C15.6812 17.9312 16.017 17.5954 16.017 17.1812C16.017 16.767 15.6812 16.4312 15.267 16.4312V17.9312ZM11.533 6.11816H8.73301V7.61816H11.533V6.11816ZM8.73121 6.11817C6.25081 6.12413 4.2447 8.13937 4.25001 10.6198L5.75001 10.6166C5.74647 8.96443 7.08269 7.62213 8.73481 7.61816L8.73121 6.11817ZM4.25001 10.6182V16.2432H5.75001V10.6182H4.25001ZM4.25001 16.2416C4.2447 18.722 6.25081 20.7372 8.73121 20.7432L8.73481 19.2432C7.08269 19.2392 5.74647 17.8969 5.75001 16.2448L4.25001 16.2416ZM8.73301 20.7432H14.333V19.2432H8.73301V20.7432ZM14.3348 20.7432C16.8152 20.7372 18.8213 18.722 18.816 16.2416L17.316 16.2448C17.3195 17.8969 15.9833 19.2392 14.3312 19.2432L14.3348 20.7432ZM18.816 16.2432V12.4932H17.316V16.2432H18.816ZM13.9998 7.25635C14.1947 7.72849 14.5923 8.39767 15.1866 8.91472C15.7944 9.44363 16.6679 9.85669 17.7319 9.62148L17.4081 8.15684C16.9431 8.25964 16.5391 8.1032 16.1712 7.78311C15.7897 7.45116 15.5134 6.99184 15.3863 6.68398L13.9998 7.25635ZM7.80001 17.9312H15.267V16.4312H7.80001V17.9312Z" fill="#E27C37" />
        </svg>

    )
}

export default EditSquare